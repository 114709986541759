import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import isEmpty from "lodash/isEmpty";
import { Edit2 } from "react-feather";
import { makeStyles } from "tss-react/mui";

import PageHeader from "../../../admin/components/common/PageHeader";
import Tooltip from "../../../admin/components/common/Tooltip";
import IdentityPoolCreateAnotherPoolButton from "../../../admin/components/workspaceDirectory/identityPools/identityPool/IdentityPoolCreateAnotherPoolButton";
import {
  useCheckPoolPermissions,
  useGetPool,
  useListUserWorkspacePools,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { useListB2BUsersWithPagination } from "../../../admin/services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../admin/services/adminPermissionsQuery";
import emptyImg from "../../../assets/images/emptyStates/workspace-users.svg";
import { getServerId } from "../../../common/api/paths";
import EmptyState from "../../../common/components/EmptyState";
import Progress from "../../../common/components/Progress";
import RouterLink from "../../../common/components/RouterLink";
import B2BUsersCreateNew from "./B2BUsersCreateNew";
import B2BUsersTable from "./B2BUsersTable";
import PopulationEdit from "./PopulationEdit";

const useStyles = makeStyles()(() => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  editButton: {
    marginLeft: 6,
  },
}));

export default function B2BPoolController() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { id, poolId } = useParams<{ id: string; poolId: string }>();
  const [editPopulationDialog, setEditPopulationDialog] = useState(false);

  const workspaceId = id || getServerId();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);
  const checkPoolPermissionsQuery = useCheckPoolPermissions(poolId ?? "");
  const getPoolQuery = useGetPool(poolId ?? "");
  const listPoolsQuery = useListUserWorkspacePools({ wid: id ?? "" });

  const listWorkspacePoolsQuery = useListUserWorkspacePools({ wid: workspaceId! });
  const usersWithPagination = useListB2BUsersWithPagination(poolId ?? "");

  const pools = listPoolsQuery.data?.pools ?? [];

  const progress =
    checkPoolPermissionsQuery.isLoading || getPoolQuery.isLoading || listPoolsQuery.isLoading;

  const isEmptyView =
    !usersWithPagination.isLoading &&
    usersWithPagination.isSuccess &&
    usersWithPagination.totalData?.length === 0 &&
    isEmpty(usersWithPagination.params.query);

  return (
    <>
      <PageHeader
        style={{ marginTop: 64 }}
        title={
          <div className={classes.flexContainer}>
            {pools.length > 1 ? (
              <div className={classes.flexContainer}>
                <span>{getPoolQuery.data?.name ?? ""}</span>
                {checkPoolPermissionsQuery.data?.update_identity_pool && (
                  <Tooltip title="Edit population" placement="bottom">
                    <IconButton
                      className={classes.editButton}
                      onClick={() => setEditPopulationDialog(true)}
                    >
                      <Edit2 size={14} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ) : (
              "Users"
            )}

            <IdentityPoolCreateAnotherPoolButton
              hasPermissions={!!checkWorkspacePermissionsQuery.data?.create_identity_pool}
              workspaceId={workspaceId}
              onCreated={pool => navigate(`/organizations/${id}/users/${pool.id}`)}
            />
          </div>
        }
        breadcrumb={
          (listWorkspacePoolsQuery.data?.pools || []).length > 1 ? (
            <RouterLink to={`/organizations/${workspaceId}/users`}>&lt; Users</RouterLink>
          ) : undefined
        }
        maxWidth={false}
      />
      <div style={{ display: "none" }} id="b2b-landing-version" data-testid="organizations-pool" />
      {progress && <Progress />}
      {!progress &&
        (usersWithPagination.isSuccess ? (
          isEmptyView ? (
            <EmptyStateView workspaceId={workspaceId} poolId={poolId ?? ""} />
          ) : (
            <B2BUsersTable
              workspaceId={workspaceId}
              identityPoolID={poolId ?? ""}
              usersWithPagination={usersWithPagination}
            />
          )
        ) : null)}

      {editPopulationDialog && (
        <PopulationEdit
          workspaceId={workspaceId}
          poolId={poolId ?? ""}
          onClose={() => setEditPopulationDialog(false)}
        />
      )}
    </>
  );
}

function EmptyStateView({ workspaceId, poolId }: { workspaceId: string; poolId: string }) {
  return (
    <EmptyState
      style={{ marginTop: 100 }}
      img={emptyImg}
      imgSize={12}
      title="No users created yet"
      description="Add or import users to your organization"
      actionButton={
        <B2BUsersCreateNew workspaceId={workspaceId} identityPoolID={poolId} onCreated={() => {}} />
      }
    />
  );
}
