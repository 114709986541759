import { PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import otpIcon from "../../../../../assets/images/icons/identityPools/otp-icon.svg";
import webauthnIcon from "../../../../../assets/images/icons/identityPools/webauthn-icon.svg";
import { providers } from "../../identities.utils";

export const IDPS_LIST_VIEW_MODE_KEY = "idps-list-view-mode";

export type IdpsViewModeType = "simple" | "advanced";

const hiddenIdps = ["static", "organization", "cloudentity"];

const imgMapper = providers.reduce(
  (acc, { icon, method }) => ({
    ...acc,
    [method]: (() => {
      const store = {};
      return name => {
        if (!store[name]) {
          const img = document.createElement("img");
          img.src = icon;
          store[name] = img;
        }
        return store[name];
      };
    })(),
  }),
  {}
);

const getImg = (src: string) => {
  const img = document.createElement("img");
  img.src = src;
  return img;
};

const poolMethodsMapper = {
  [PoolResponseAuthenticationMechanismsEnum.Otp]: {
    img: getImg(otpIcon),
    name: "Verification Code",
  },
  [PoolResponseAuthenticationMechanismsEnum.Webauthn]: {
    img: getImg(webauthnIcon),
    name: "Passkey",
  },
};

function createButton(label: string, img: HTMLImageElement) {
  const button = document.createElement("button");
  button.className = "href-card visible-flex";
  button.style.minHeight = "47px";
  const span = document.createElement("span");
  span.textContent = label;
  const div = document.createElement("div");
  div.appendChild(img);
  div.appendChild(span);
  const i = document.createElement("i");
  i.className = "material-icons";
  i.textContent = "navigate_next";
  button.appendChild(div);
  button.appendChild(i);
  return button;
}

export function createIdpButton(name: string, method: string) {
  if (hiddenIdps.includes(method)) return null;

  const provider = providers.find(provider => provider.method === method);
  if (!provider) return;

  const img = imgMapper[method](name);
  return createButton(name, img);
}

export function createPoolMethodButton(method: PoolResponseAuthenticationMechanismsEnum) {
  const button = document.createElement("button");
  button.className = "href-card visible-flex";
  button.style.minHeight = "47px";

  const methodData = poolMethodsMapper[method];
  return createButton(methodData.name, methodData.img);
}

export function handleIdpDiscoveryEnabled(content: Document, enabled: boolean) {
  const header = content.querySelector("#log-in-header") as HTMLElement;
  const usernamePasswordForm = content.querySelector("#username-password-form") as HTMLElement;

  if (header) {
    header.textContent = enabled ? "Log in to continue" : "Log in with";
  }

  if (usernamePasswordForm) {
    usernamePasswordForm.style.display = enabled ? "block" : "none";
  }
}

export function handleAddSeparator(methodsContainer: HTMLElement, show: boolean) {
  const container = document.createElement("div");
  const div = document.createElement("div");
  div.textContent = "or login with";
  container.className = "spacer-container";

  if (show) {
    container.appendChild(div);
    methodsContainer.appendChild(container);
  }
}

export function handlePoolNoMethods(form: HTMLElement, show: boolean) {
  const noMethodsInfo = form.querySelector("#no-methods-info") as HTMLElement;
  if (noMethodsInfo) {
    form.removeChild(noMethodsInfo);
  }

  if (show) {
    const div = document.createElement("div");
    div.id = "no-methods-info";
    div.textContent = "No methods available to log in";
    form.appendChild(div);
  }
}

export function handlePasswordMethodFields(content: Document, show: boolean) {
  const formFields = content.querySelectorAll(".form-field");
  const submitButton = content.querySelector(".submit-button") as HTMLElement;
  formFields.forEach(field => {
    (field as HTMLElement).style.display = show ? "block" : "none";
  });
  if (submitButton) {
    submitButton.style.display = show ? "block" : "none";
  }
}
