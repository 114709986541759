import { makeStyles } from "tss-react/mui";

export const TRANSPARENT_HEX_VALUE = "#00000000";
export const TOP_BAR_HEIGHT = 64;

export const useTopBarStyles = makeStyles<{ stripped?: boolean }>()((theme, { stripped }) => {
  const isHeaderTransparent = theme.custom.headerBackground === TRANSPARENT_HEX_VALUE;

  return {
    appBar: {
      backgroundColor: theme.custom.headerBackground,
      height: TOP_BAR_HEIGHT,
      borderRadius: 0,
    },
    toolBar: {
      padding: "0 !important",
      "& > first-of-child": {
        height: "100%",
      },
    },
    spacing: {
      flex: 1,
    },

    menuItem: {
      padding: "8px 16px",
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.dark,
        width: "100%",
      },
    },
    menuItemTitle: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
      padding: "14px 24px",
    },
    menuPaper: {
      borderRadius: 4,
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1), 0px 3px 25px rgba(0, 0, 0, 0.1)",
      width: 258,
    },
    menuList: {
      padding: 0,
    },
    profileButton: {
      color: isHeaderTransparent ? theme.palette.primary.main : "white",
      marginRight: 22,
    },
    avatar: {
      border: `solid 2px ${isHeaderTransparent ? theme.palette.primary.main : "white"}`,
      borderRadius: "50%",
      fontSize: 12,
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    textEllipsis: {
      textAlign: "left",
      maxWidth: 150,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    name: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "20px",
    },
    tenantName: {
      fontSize: 12,
      lineHeight: "16px",
    },
    tenantNameLabel: {
      color: theme.custom.greys.textDisabled,
      marginRight: 4,
    },
    tenantNameSpace: {
      width: 12,
    },
    hideDesktop: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    iconButton: {
      "& .MuiTouchRipple-child": {
        backgroundColor: "white",
      },
    },
    listItem: {
      padding: "8px 24px",
      "& p": {
        fontSize: 13,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 35,
      },
    },
    profileSettingsLabel: {
      fontSize: 12,
      paddingTop: 10,
      paddingLeft: 24,
    },
    link: {
      display: "flex",
      alignItems: "center",
      color: "inherit",
    },
    version: {
      color: "#9EA1B7",
      backgroundColor: "#FBFCFD",
      padding: "8px 24px",
      margin: 0,
    },
    divider: {
      borderColor: "#F2F4FF",
    },
    logo: {
      verticalAlign: "middle",
      width: "auto",
      height: 36,
      maxWidth: 350,
      objectFit: "contain",
      marginLeft: 20,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 174,
    },
    listItemB2B: {
      cursor: "default",
    },
  };
});
