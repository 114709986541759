import React, { useEffect, useMemo, useState } from "react";

import Typography from "@mui/material/Typography";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import { makeStyles } from "tss-react/mui";

import {
  GrantWorkspaceRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestTypeEnum,
  OrganizationResponse,
} from "@cloudentity/acp-admin";
import {
  BaseNewUserPayloadStatusEnum,
  BaseUserWithData,
  NewUserIdentifierTypeEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  Pool,
  PoolAuthenticationMechanismsEnum,
} from "@cloudentity/acp-identity";

import useOrganizationsSeqOrCursor from "../../admin/components/common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import Domains from "../../admin/components/settings/Domains";
import SchemaForm from "../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import adminB2BUsersApi from "../../admin/services/adminB2BUsersApi";
import { useGetEnvironment } from "../../admin/services/adminEnvironmentQuery";
import { listIDPsQueryKey } from "../../admin/services/adminIDPsQuery";
import identityPoolsApi from "../../admin/services/adminIdentityPoolsApi";
import identityUsersApi from "../../admin/services/adminIdentityUsersApi";
import adminOrganizationsApi from "../../admin/services/adminOrganizationsApi";
import {
  listOrganizationsQueryKey,
  useGetOrganization,
} from "../../admin/services/adminOrganizationsQuery";
import adminRolesApi from "../../admin/services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../admin/services/adminRolesQuery";
import { getExecutionPointsQueryKey } from "../../admin/services/adminScriptsQuery";
import adminServersApi from "../../admin/services/adminServersApi";
import { getTenantId } from "../../common/api/paths";
import Dialog from "../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../common/components/notifications/notificationService";
import Form, { useForm } from "../../common/utils/forms/Form";
import FormFooter from "../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../common/utils/forms/TextFieldRequired";
import { validators } from "../../common/utils/forms/validation";
import { toSlugString } from "../../common/utils/string.utlis";
import TemplatesSelect from "./TemplatesSelect";

const useStyles = makeStyles()(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  addLabel: {
    marginRight: 8,
    color: theme.palette.primary.main,
  },
}));

export interface B2BAddOrganizationProps {
  onCancel: () => void;
  onCreated?: (server: OrganizationResponse) => void;
  onSkip: (server: OrganizationResponse) => void;
  onInviteSent: (server: OrganizationResponse) => void;
  organizationParentId?: string;
}

export default function B2BAddOrganization({
  onCancel,
  onCreated,
  onSkip,
  onInviteSent,
  organizationParentId,
}: B2BAddOrganizationProps) {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(false);
  const [step, setStep] = useState<"create" | "invite">("create");

  const tenantId = getTenantId();
  const [newPoolId, setNewPoolId] = useState("");
  const [newOrganization, setNewOrganization] = useState<OrganizationResponse | null>(null);

  const queryClient = useQueryClient();

  const environmentQuery = useGetEnvironment();

  const organizationsData = useOrganizationsSeqOrCursor({
    forceMode: "seq",
    template: true,
  });

  const templates: { id?: string; name?: string }[] = organizationsData.totalData;

  const defaultTemplateId = useMemo(
    () => environmentQuery.data?.tenant_settings?.default_template_id,
    [environmentQuery.data]
  );
  const defaultTemplate = templates.find(template => template.id === defaultTemplateId) || null;

  const data = useMemo(
    () => ({ id: "", name: "", email: "", firstName: "", lastName: "", template: defaultTemplate }),
    [defaultTemplate]
  );

  const form = useForm({
    id: "add-organization",
    progress,
    initialValues: data,
  });

  const selectedTemplate = form.watch("template");

  useEffect(() => {
    setPayload({});
  }, [selectedTemplate?.id]);

  const templateOrganizationId = defaultTemplateId || templates.at(0)?.id;

  const templateOrganizationQuery = useGetOrganization(templateOrganizationId, {
    enabled: !!templateOrganizationId,
  });

  // form
  const [payload, setPayload] = useState({});

  const schemaWithMappedTitles = mapFieldNameToTitle(
    templateOrganizationQuery.data?.metadata?.schema ?? {}
  );
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  const validateMetadata = validator.validateFormData(
    payload,
    (schemaWithMappedTitles as JSONSchema7) || {}
  );
  // end

  const handleCreateIdentityPool = (responseData: OrganizationResponse) => {
    const pool: Pool = {
      name: "Users",
      tenant_id: tenantId,
      authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
      workspace_id: responseData.id,
      identifier_case_insensitive: true,
      metadata_schema_id: "organizations_pool_default_metadata",
      payload_schema_id: "organizations_pool_default_payload",
    };
    return identityPoolsApi.createWorkspacePool({
      wid: responseData.id ?? "",
      pool,
      withIdp: true,
    });
  };

  const handleSendActivationMessage = (user: BaseUserWithData) => {
    identityUsersApi
      .sendActivationMessage({
        ipID: user.user_pool_id,
        userID: user.id ?? "",
        serverId: newOrganization?.id,
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to send invitation message"));
  };

  const handleCreateOrganization = data => {
    let createdOrganization;
    let templateIdExists = false;
    let workspaceIdExists = false;
    let omitInvite = false;

    setProgress(true);
    const templateId = "template" in data ? data.template?.id : defaultTemplateId;

    const parentId =
      organizationParentId || environmentQuery.data?.tenant_settings?.default_workspace_id;

    Promise.allSettled([
      templateId ? adminOrganizationsApi.getOrganization({ wid: templateId }) : Promise.resolve(),
      parentId ? adminServersApi.getWorkspace({ wid: parentId }) : Promise.resolve(),
    ])
      .then(promises => {
        if (promises[0].status === "fulfilled") {
          templateIdExists = true;
        }
        if (promises[1].status === "fulfilled") {
          workspaceIdExists = true;
        }
      })
      .then(() =>
        adminOrganizationsApi.createOrganization({
          org: {
            name: data.name.trim(),
            id: data.id.trim(),
            domains: (data.domains || []).map(d => (typeof d === "string" ? d : d.value)),
            // color: initialColors[Math.floor(Math.random() * initialColors.length)],
            template_id: templateIdExists && templateId ? templateId : undefined,
            parent_id: workspaceIdExists ? parentId : undefined,
            metadata: { payload },
          },
        })
      )
      .then(({ data }) => {
        createdOrganization = data;
        setNewOrganization(data);
        if (templateIdExists && templateId) return;
        return handleCreateIdentityPool(data);
      })
      .then(async res => {
        if (res) {
          setStep("invite");
          setNewPoolId(res?.data.id ?? "");
        } else {
          const newOrgPoolsRes = await identityPoolsApi.listWorkspacePools({ wid: data.id });
          if (newOrgPoolsRes?.data.pools?.length === 1) {
            setNewPoolId(newOrgPoolsRes.data.pools[0].id ?? "");
            setStep("invite");
          } else {
            omitInvite = true;
          }
        }
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => {
        if (workspaceIdExists && parentId) {
          return queryClient.invalidateQueries({
            queryKey: getExecutionPointsQueryKey(tenantId, parentId),
          });
        }
      })
      .then(() => onCreated && onCreated(createdOrganization))
      .then(() =>
        notifySuccess(
          <span>
            <strong>{data.name}</strong> organization successfully created
          </span>
        )
      )
      .catch(err => {
        if (
          err.response?.status === 409 &&
          err.response?.data.error?.includes("id must be unique")
        ) {
          form.setError(
            "id",
            {
              message: "Organization ID with given value already exists",
            },
            { shouldFocus: true }
          );
        } else {
          notifyErrorOrDefaultTo("Error occurred while trying to create organization")(err);
        }
      })
      .finally(() => {
        setProgress(false);
        if (omitInvite) {
          onCancel();
        }
      });
  };

  const handleInvite = data => {
    setProgress(true);
    adminB2BUsersApi
      .createB2BUser({
        ipID: newPoolId,
        newUser: {
          payload: {
            first_name: data.firstName.trim(),
            last_name: data.lastName.trim(),
          },
          status: BaseNewUserPayloadStatusEnum.New,
          credentials: [],
          identifiers: [
            {
              identifier: data.email,
              type: NewUserIdentifierTypeEnum.Email,
            },
          ],
          verifiable_addresses: [
            {
              address: data.email,
              status: NewUserVerifiableAddressStatusEnum.Active,
              type: NewUserVerifiableAddressTypeEnum.Email,
              verified: false,
            },
          ],
        },
      })
      .then(res => {
        setNewPoolId("");
        return res;
      })
      .then(res => {
        return adminRolesApi
          .grantWorkspaceRole({
            wid: newOrganization?.id!,
            request: {
              tenant_id: getTenantId(),
              role: GrantWorkspaceRoleRequestRoleEnum.Manager,
              type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
              identity_pool_id: res.data.user_pool_id,
              identity_pool_user_id: res.data.id,
            },
          })
          .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
          .then(() =>
            queryClient.invalidateQueries({
              queryKey: listUserRoles(res.data.user_pool_id, res.data.id),
            })
          )
          .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"))
          .then(() => res);
      })
      .then(({ data }) => handleSendActivationMessage(data))
      .then(() => onInviteSent(newOrganization!))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to add user"))
      .then(() =>
        notifySuccess(
          <span>
            <strong>{data.firstName.trim() + " " + data.lastName.trim()}</strong> added to{" "}
            <strong>{newOrganization?.name}</strong> as an <strong>Administrator</strong>
          </span>
        )
      )
      .finally(() => {
        setProgress(false);
        onCancel();
      });
  };

  const handleChangeName = e => {
    const slugId = toSlugString(e.target.value);

    form.clearErrors("id");
    form.setValue("id", slugId);
  };

  return (
    <Dialog
      onClose={onCancel}
      id="add-organization-dialog"
      aria-label="Add organization dialog"
      title={
        step === "create" ? (
          <div className={classes.header}>
            <div>Create new organization</div>
            <Typography variant="body2">Step 1 / 2</Typography>
          </div>
        ) : (
          <div className={classes.header}>
            <div>Create new Organization Admin</div>
            <Typography variant="body2">Step 2 / 2</Typography>
          </div>
        )
      }
    >
      <Form form={form} noFormTag>
        {step === "create" && (
          <>
            <TextFieldRequired name="name" label="Name" onChange={handleChangeName} autoFocus />
            <TextFieldRequired
              name="id"
              label="Organization ID"
              rules={{
                validate: {
                  validID: validators.validID({ label: "Organization ID" }),
                },
              }}
              helperText="Identifier cannot be modified after organization creation"
              tooltip="Organization identifier uniquely identifies organization and is visible in the organization login page URL"
            />

            <Domains />

            {!(templates.length === 1 && !!defaultTemplate) && (
              <TemplatesSelect
                templates={templates}
                data={organizationsData}
                disableClearable={!!defaultTemplate}
              />
            )}

            <SchemaForm
              formData={payload}
              setFormData={setPayload}
              schema={schemaWithMappedTitles}
              UISchema={uiSchema}
              submitAttempt={true}
              extraErrors={{}}
              resetExtraErrors={() => {}}
            />

            <FormFooter
              disabled={validateMetadata.errors.length > 0}
              onCancel={onCancel}
              onSubmit={data => handleCreateOrganization(data)}
              submitText="Create"
            />
          </>
        )}
        {step === "invite" && (
          <>
            <TextFieldRequired
              name="email"
              label="Email"
              rules={{
                validate: {
                  validEmail: validators.validEmail({ label: "Value" }),
                },
              }}
            />
            <TextFieldRequired name="firstName" label="First name" />
            <TextFieldRequired name="lastName" label="Last name" />
            <FormFooter
              onCancel={() => onSkip(newOrganization!)}
              cancelText="Skip"
              onSubmit={data => handleInvite(data)}
              submitText="Send Invite"
            />
          </>
        )}
      </Form>
    </Dialog>
  );
}
