import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";

import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../common/api/paths";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import adminTenantsApi from "../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../services/adminTenantsQuery";
import { useGetUserInfo } from "../../services/oauth2Query";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import TopBar from "../nav/TopBar";
import { TenantMetadataRegistration } from "../welcome/workspaces/SetupBranding";
import GetStartedCard from "./GetStartedCard";
import getStartedAuthentication from "./get-started-authentication.svg";
import getStartedAuthorization from "./get-started-authorization.svg";
import getStartedOpenBanking from "./get-started-open-banking.svg";

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: TOP_BAR_HEIGHT,
    position: "relative",
  },
  title: {
    margin: "10px 0",
  },
  intro: {
    margin: "0 28px",
  },
  loading: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
  },
}));

enum UseCaseType {
  "authentication" = "authentication",
  "authorization" = "authorization",
  "openbanking" = "openbanking",
}

export const useMarkGetStartedVisited = () => {
  const [progress, setProgress] = useState(false);

  const getTenantQuery = useGetTenant(getTenantId());
  const queryClient = useQueryClient();

  const markGetStartedVisited = useCallback(() => {
    setProgress(true);
    return adminTenantsApi
      .updateTenant({
        tenant: {
          ...getTenantQuery.data,
          metadata: { ...getTenantQuery.data?.metadata, should_visit_get_started: false } as any,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantId()) }))
      .catch(
        handleErrorWithNotify("Error occurred while trying to mark Get Started flow as visited")
      )
      .finally(() => setProgress(true));
  }, [getTenantQuery.data, queryClient]);

  return { markGetStartedVisited, progress };
};

export default function GetStarted() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const userinfoQuery = useGetUserInfo();
  const tenantQuery = useGetTenant(getTenantId());
  const { markGetStartedVisited, progress: markProgress } = useMarkGetStartedVisited();

  const firstName =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.first_name ||
    userinfoQuery.data?.given_name ||
    "";

  const handleContinueClick = (selected: UseCaseType) => () => {
    switch (selected) {
      case UseCaseType.authentication: {
        navigate("/setup-branding");
        return;
      }
      case UseCaseType.authorization: {
        markGetStartedVisited().then(() => {
          navigate("/demo/overview");
        });
        return;
      }
      case UseCaseType.openbanking: {
        navigate("/select-ecosystem");
        return;
      }
    }
  };

  const progress = markProgress || tenantQuery.isLoading;

  return (
    <PageContainer progress={progress}>
      <TopBar hideTenantSettings />
      <div className={classes.container}>
        <PageContent fullWidth={false} style={{ marginLeft: "auto" }}>
          <div className={classes.intro}>
            <div>Welcome{firstName ? " " + firstName : ""},</div>
            <Typography variant="h2" className={classes.title}>
              What is your use case?
            </Typography>

            <div
              style={{
                marginTop: 24,
                marginBottom: 24,
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <GetStartedCard
                label="Identity for SaaS Apps"
                icon={
                  <img
                    src={getStartedAuthentication}
                    style={{ width: 42, height: 42 }}
                    alt="get started card icon"
                  />
                }
                selectable={!progress}
                onSelect={handleContinueClick(UseCaseType.authentication)}
                id="sign-in-mode-card"
              />
              <GetStartedCard
                label="Open Finance Compliance"
                icon={
                  <img
                    src={getStartedOpenBanking}
                    style={{ width: 42, height: 42 }}
                    alt="get started card icon"
                  />
                }
                selectable={!progress}
                onSelect={handleContinueClick(UseCaseType.openbanking)}
                id="openbanking-mode-card"
              />
              <GetStartedCard
                label="Authorization for Apps, APIs and Data"
                icon={
                  <img
                    src={getStartedAuthorization}
                    style={{ width: 42, height: 42 }}
                    alt="get started card icon"
                  />
                }
                selectable={!progress}
                onSelect={handleContinueClick(UseCaseType.authorization)}
                id="authorize-mode-card"
              />
            </div>
          </div>
          <div></div>
        </PageContent>
      </div>
    </PageContainer>
  );
}
