import React, { useMemo, useState } from "react";

import Editor, { loader } from "@monaco-editor/react";
import Button from "@mui/material/Button";
import isEqual from "lodash/isEqual";
import { makeStyles } from "tss-react/mui";

import Chip from "../../../../../common/components/Chip";
import RouteLeavingGuardSimple from "../../../../../common/components/RouteLeavingGuardSimple";
import { notifyError } from "../../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions, useGetPool } from "../../../../services/adminIdentityPoolsQuery";
import PageContent from "../../../common/PageContent";
import { SaveFormType } from "../utils";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(() => ({
  editorContainer: {
    border: "1px solid #D5D7D9",
    borderRadius: 4,
    padding: 1,
    height: "calc(100% - 52px)",
  },
  editor: {
    maxWidth: "99%",
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: "bold",
      },
    },
  },
  labels: {
    paddingTop: 8,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface Props {
  poolId: string;
  handleUpdate: (data: SaveFormType) => void;
}

function IdentityPoolMetadata({ poolId, handleUpdate }: Props) {
  const { classes } = useStyles();

  const poolQuery = useGetPool(poolId);
  const checkPoolPermissionsQuery = useCheckPoolPermissions(poolId);

  const originalMetadata = useMemo(() => poolQuery.data?.metadata, [poolQuery.data]);

  const [metadata, setMetadata] = useState(JSON.stringify(poolQuery.data?.metadata ?? {}, null, 2));
  const [isSyntaxError, setSyntaxError] = useState(false);

  const handleValidate = annotations => setSyntaxError(annotations && annotations.length > 0);

  const isEdited = () => {
    try {
      const metadataJson = JSON.parse(metadata);
      return !isEqual(originalMetadata, metadataJson);
    } catch {
      return true;
    }
  };

  const handleSave = () => {
    try {
      const newMetadata = JSON.parse(metadata);
      handleUpdate({ ...(poolQuery.data as any), metadata: newMetadata });
    } catch (e) {
      notifyError("Metadata is not a valid JSON");
    }
  };

  return (
    <PageContent noScrollHeight style={{ minWidth: 560 }}>
      <div className={classes.editorContainer}>
        <Editor
          defaultLanguage="json"
          options={{
            minimap: {
              enabled: false,
            },
            scrollBeyondLastLine: false,
            folding: false,
            lineNumbersMinChars: 3,
            automaticLayout: true,
            readOnly: !checkPoolPermissionsQuery.data?.update_identity_pool,
          }}
          value={metadata}
          onChange={v => setMetadata(v ?? "")}
          wrapperProps={{
            id: "identity-pool-metadata-editor",
          }}
          onValidate={handleValidate}
          className={classes.editor}
        />
      </div>
      <div className={classes.labels}>
        <Chip label="JSON" />
        {checkPoolPermissionsQuery.data?.update_identity_pool && (
          <Button
            id="identity-pool-metadata-save-button"
            variant="contained"
            style={{ marginTop: 8 }}
            disabled={!metadata || isSyntaxError || !isEdited()}
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </div>
      <RouteLeavingGuardSimple when={isEdited()} />
    </PageContent>
  );
}

export default IdentityPoolMetadata;
