import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Grid from "@mui/material/Grid";
import { useQueryClient } from "@tanstack/react-query";

import { PoolResponse, UserPools } from "@cloudentity/acp-identity";

import useItemsWithQuery, {
  useInfiniteScrollForItemsWithQuery,
} from "../../../admin/components/common/EnhancedTableAsync/useItemsWithQuery";
import PageContainer from "../../../admin/components/common/PageContainer";
import PageContent from "../../../admin/components/common/PageContent";
import PageHeader from "../../../admin/components/common/PageHeader";
import AddTeamDialog from "../../../admin/components/workspaceDirectory/administrator/AddTeamDialog";
import IdentityPoolsGridItem from "../../../admin/components/workspaceDirectory/identityPools/identityPoolsList/IdentityPoolsGridItem";
import WorkspacesToolbar from "../../../admin/components/workspaceDirectory/workspaces/WorkspacesToolbar";
import {
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
  useListIDPs,
} from "../../../admin/services/adminIDPsQuery";
import identityPoolsApi from "../../../admin/services/adminIdentityPoolsApi";
import {
  listUserWorkspacePoolsQueryKey,
  listWorkspacePoolsQueryKey,
  useListUserWorkspacePools,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../../admin/services/adminPermissionsQuery";
import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import { getTenantId } from "../../../common/api/paths";
import Progress from "../../../common/components/Progress";
import RemoveConfirmationDialog from "../../../common/components/RemoveConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import PoolMenu from "./PoolMenu";
import PopulationEdit from "./PopulationEdit";

const limit = 50;

interface Props {
  workspaceId: string;
}

export default function B2BPoolsList({ workspaceId }: Props) {
  const navigate = useNavigate();
  const listPoolsQuery = useListUserWorkspacePools({ wid: workspaceId });
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);
  const queryClient = useQueryClient();

  const [progress, setProgress] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const [removeDialog, setRemoveDialog] = useState<PoolResponse>();
  const [editDialog, setEditDialog] = useState<PoolResponse>();

  const [menu, setMenu] = useState<{ elementId: string; pool: PoolResponse }>();

  const cursorData = useItemsWithQuery<PoolResponse, UserPools>({
    id: "user-pools-cursor",
    idGetter: (_, queryData) => queryData.cursor,
    getArray: queryData => {
      const data = queryData?.pools ?? [];
      return {
        data: data.slice(0, limit),
        nextPageAvailable: data.length > limit,
      };
    },
    getTotalItemsQueryKey: () => listUserWorkspacePoolsQueryKey(workspaceId, limit + 1),
    useQueryFn: page =>
      useListUserWorkspacePools(
        {
          wid: workspaceId,
          limit: limit + 1,
          cursor: page.afterItemId,
        },
        { keepPreviousData: true, enabled: true }
      ),
    initialSort: "name",
  });

  const { totalData, onLastPage } = cursorData;
  const isEmpty = totalData.length === 0;

  useInfiniteScrollForItemsWithQuery({ onLastPage });

  const idpsListQuery = useListIDPs(getTenantId(), workspaceId);
  const userInfoQuery = useGetUserInfo();

  const canBeDeleted = pool =>
    (idpsListQuery.data?.idps || []).find(idp => idp.identity_pool_id === pool.id)?.id !==
    userInfoQuery.data?.idp;

  const handleDelete = () => {
    if (!removeDialog) return;

    setProgress(true);
    identityPoolsApi
      .deleteWorkspacePool({
        wid: workspaceId,
        ipID: removeDialog.id ?? "",
        withIdp: true,
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUserWorkspacePoolsQueryKey(workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .then(() => setRemoveDialog(undefined))
      .then(() => notifySuccess("User population removed successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete user population"))
      .finally(() => setProgress(false));
  };

  useEffect(() => {
    if (listPoolsQuery.data?.pools?.length === 0 && listPoolsQuery.isSuccess) {
      navigate("/error");
    }

    if (listPoolsQuery.data?.pools?.length === 1 && listPoolsQuery.isSuccess) {
      navigate(`/organizations/${workspaceId}/users/${listPoolsQuery.data?.pools?.at(0)?.id}`);
    }
  }, [workspaceId, listPoolsQuery.data, listPoolsQuery.isSuccess, navigate]);

  if (listPoolsQuery.isFetching || !listPoolsQuery.isSuccess) {
    return <Progress />;
  }

  return (
    <PageContainer>
      <PageHeader style={{ marginTop: 64 }} title="User Populations" maxWidth={false} />
      <PageContent>
        <WorkspacesToolbar
          searchText=""
          onCreate={
            checkWorkspacePermissionsQuery.data?.create_identity_pool
              ? () => setCreateDialog(true)
              : undefined
          }
          createText="Create population"
        />
        <div
          style={{ display: "none" }}
          id="b2b-landing-version"
          data-testid="organizations-pools-list"
        ></div>

        <Grid container spacing={3}>
          {!isEmpty ? (
            totalData.map(pool => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={pool!.id}>
                <IdentityPoolsGridItem
                  pool={pool}
                  onSelect={() => navigate(`/organizations/${workspaceId}/users/${pool.id}`)}
                  onMenuOpen={setMenu}
                  showIcons={false}
                />
              </Grid>
            ))
          ) : (
            <div style={{ margin: 32, color: "gray" }}>No user populations</div>
          )}

          {createDialog && (
            <AddTeamDialog
              onCreated={() => setCreateDialog(false)}
              onClose={() => setCreateDialog(false)}
            />
          )}
        </Grid>

        {menu && (
          <PoolMenu
            pool={menu.pool}
            anchorEl={() => document.querySelector(`#${menu.elementId}`) ?? document.body}
            handleClose={() => setMenu(undefined)}
            onSelect={() => navigate(`/organizations/${workspaceId}/users/${menu.pool.id}`)}
            handleEdit={pool => setEditDialog(pool)}
            handleDelete={canBeDeleted(menu.pool) ? pool => setRemoveDialog(pool) : undefined}
          />
        )}

        {editDialog && (
          <PopulationEdit
            workspaceId={workspaceId}
            poolId={editDialog.id ?? ""}
            onClose={() => setEditDialog(undefined)}
          />
        )}

        {removeDialog && (
          <RemoveConfirmationDialog
            title="Delete user population"
            name={removeDialog.name}
            type="user population"
            progress={progress || idpsListQuery.isLoading}
            onConfirm={handleDelete}
            onCancel={() => setRemoveDialog(undefined)}
          />
        )}
      </PageContent>
    </PageContainer>
  );
}
