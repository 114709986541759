import { useMutation } from "@tanstack/react-query";

import { OrganizationsApiUpdateOrganizationRequest } from "@cloudentity/acp-admin";

import { getTenantId } from "../../common/api/paths";
import adminOrganizationsApi from "./adminOrganizationsApi";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
  listUserOrganizationsQueryKey,
} from "./adminOrganizationsQuery";
import {
  getAuthorizationServerQueryKey,
  getAuthorizationServersQueryKey,
  getWorkspaceQueryKey,
  listUserWorkspacesQueryKey,
  listWorkspacesQueryKey,
} from "./adminServersQuery";
import { queryClient } from "./queryClient";

export const useOrganizationMutation = ({ wid }: { wid: string }) => {
  return useMutation({
    mutationFn: (req: OrganizationsApiUpdateOrganizationRequest) => {
      return adminOrganizationsApi.updateOrganization(req);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), wid),
        }),
        queryClient.invalidateQueries({ queryKey: getAuthorizationServersQueryKey }),
        queryClient.invalidateQueries({ queryKey: getWorkspaceQueryKey(wid) }),
        queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }),
        queryClient.invalidateQueries({ queryKey: listUserWorkspacesQueryKey() }),
        queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(wid) }),
        queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }),
        queryClient.invalidateQueries({ queryKey: listUserOrganizationsQueryKey() }),
      ]),
  });
};
