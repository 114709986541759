import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

export default function RouterLink({ children, className, ...props }: LinkProps) {
  const { cx, classes } = useStyles();
  return (
    <Link className={cx(classes.link, className)} {...props}>
      {children}
    </Link>
  );
}
