import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";

import { Pool } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import Breadcrumbs from "../../../../../common/components/Breadcrumbs";
import Progress from "../../../../../common/components/Progress";
import { TOP_BAR_HEIGHT } from "../../../../../common/components/nav/utils";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../common/components/notifications/notificationService";
import adminIDPsApi from "../../../../services/adminIDPsApi";
import {
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
  useListIDPs,
} from "../../../../services/adminIDPsQuery";
import adminIdentityPoolsApi from "../../../../services/adminIdentityPoolsApi";
import {
  getPoolQueryKey,
  listPoolsQueryKey,
  listWorkspacePoolsQueryKey,
  useCheckPoolPermissions,
  useGetPool,
  useListWorkspacePools,
} from "../../../../services/adminIdentityPoolsQuery";
import { useListSchemas } from "../../../../services/adminIdentitySchemasQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../../../../services/adminPermissionsQuery";
import PageHeader from "../../../common/PageHeader";
import { scrollToElement } from "../../../common/utils";
import {
  addDefaultPreferredAuthMechanism,
  convertDaysToDuration,
  SaveFormType,
  usePoolRootUrl,
} from "../utils";
import IdentityPoolConfiguration from "./IdentityPoolConfiguration";
import IdentityPoolCreateAnotherPoolButton from "./IdentityPoolCreateAnotherPoolButton";
import IdentityPoolMetadata from "./IdentityPoolMetadata";
import IdentityPoolSchemas from "./IdentityPoolSchemas";
import IdentityPoolSigninAndSignup from "./IdentityPoolSigninAndSignup";
import IdentityPoolUsersController from "./users/list/IdentityPoolUsersController";

interface Props {
  id: string;
  serverId: string;
  tab: string;
  hideUsers?: boolean;
  hideBreadcrumb?: boolean;
  hideCreate?: boolean;
  onTabChange: (tab: string) => void;
}

export default function IdentityPool({
  id,
  serverId,
  tab,
  hideUsers,
  hideBreadcrumb,
  hideCreate,
  onTabChange,
}: Props) {
  const tenantId = getTenantId();
  const { rootUrl, mode } = usePoolRootUrl();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { section: string } | undefined;
  const [progress, setProgress] = useState(false);
  const [expandedSettingsAccordions, setExpandedSettingsAccordions] = useState<string[]>(
    state?.section ? [state?.section] : ["sign-in"]
  );

  const checkTenantPermissions = useCheckTenantPermissions();
  const checkPoolPermissions = useCheckPoolPermissions(id);
  const canListSchemas = !!checkTenantPermissions.data?.list_identity_pools;

  useEffect(() => {
    if (state?.section) {
      setTimeout(() => {
        scrollToElement(`accordion-label-${state?.section}`, TOP_BAR_HEIGHT + 8);
      });
    }
  }, [state?.section]);

  const queryClient = useQueryClient();
  const poolQuery = useGetPool(id);
  const listWorkspacePoolsQuery = useListWorkspacePools(
    { wid: serverId },
    { enabled: mode === "workspace" }
  );
  const schemasQuery = useListSchemas(tenantId, { enabled: canListSchemas });
  const idpsListQuery = useListIDPs(getTenantId(), serverId);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId, {
    enabled: mode === "workspace" && !!serverId,
  });

  const pool = poolQuery.data;
  const schemas = schemasQuery.data?.schemas || [];
  const workspacePools = useMemo(
    () => listWorkspacePoolsQuery.data?.pools ?? [],
    [listWorkspacePoolsQuery.data]
  );

  const handleUpdate = (data: SaveFormType) => {
    setProgress(true);

    const newData: Pool = {
      ...data,
      password_settings: {
        ...data.password_settings,
        expires_after: convertDaysToDuration(data.password_settings?.expires_after),
      },
    };

    const rawPayload = { ...pool, ...omit(newData, "admin_initiated_registration") };
    const payload = addDefaultPreferredAuthMechanism(rawPayload);

    adminIdentityPoolsApi
      .updatePool({ ipID: id, pool: payload })
      .then(res => queryClient.setQueryData(getPoolQueryKey(tenantId, id), res.data))
      .then(() => notifySuccess("Pool updated successfully"))
      .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(tenantId) }))
      .then(() => {
        if (mode === "workspace") {
          queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(serverId) });
        }
      })
      .then(() => {
        if (mode === "workspace" && data.name && pool?.name !== data.name) {
          const poolIdpsWithSameName =
            idpsListQuery.data?.idps?.filter(
              idp => idp.identity_pool_id === pool?.id && idp.name === pool?.name
            ) ?? [];
          return Promise.all(
            poolIdpsWithSameName.map(idp =>
              adminIDPsApi.updateIDP({
                aid: idp.authorization_server_id,
                type: idp.method,
                iid: idp.id,
                body: { ...idp, name: data.name },
              })
            )
          )
            .then(() =>
              queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId, serverId) })
            )
            .then(() =>
              queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(tenantId) })
            );
        }
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update pool"))
      .finally(() => setProgress(false));
  };

  return (
    <div style={{ position: "relative" }}>
      {poolQuery.isLoading ||
      schemasQuery.isLoading ||
      listWorkspacePoolsQuery.isLoading ||
      progress ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                {mode === "workspace" && workspacePools.length <= 1 ? "Users" : pool?.name ?? ""}
                {mode === "workspace" && !hideCreate && (
                  <IdentityPoolCreateAnotherPoolButton
                    hasPermissions={!!checkWorkspacePermissionsQuery.data?.create_identity_pool}
                    onCreated={pool => {
                      navigate(`${rootUrl}/${pool?.id ?? ""}/users`);
                    }}
                    workspaceId={serverId}
                    mode="admin"
                  />
                )}
              </div>
            }
            breadcrumb={
              (workspacePools.length > 1 || mode !== "workspace") && !hideBreadcrumb ? (
                <Breadcrumb label={pool?.name ?? "details"} rootUrl={rootUrl} />
              ) : undefined
            }
            tabs={[
              ...(checkPoolPermissions.data?.read_identity_pool_users && !hideUsers
                ? [<Tab label="Users" value="users" key="pool-users" id="pool-users" />]
                : []),
              <Tab
                label="Sign-in and Sign-up"
                value="signin-and-signup"
                key="pool-signin-and-signup"
                id="pool-signin-and-signup"
              />,
              ...(canListSchemas
                ? [<Tab label="Schemas" value="schemas" key="pool-schemas" id="pool-schemas" />]
                : []),
              <Tab label="Metadata" value="metadata" key="pool-metadata" id="pool-metadata" />,
              <Tab label="Settings" value="settings" key="pool-settings" id="pool-settings" />,
            ]}
            onChangeTab={onTabChange}
            currentTab={tab}
            maxWidth={false}
          />

          {tab === "users" && checkPoolPermissions.data?.read_identity_pool_users && pool && (
            <IdentityPoolUsersController pool={pool} />
          )}

          {tab === "signin-and-signup" && (
            <IdentityPoolSigninAndSignup
              handleUpdate={handleUpdate}
              expandedSettingsAccordions={expandedSettingsAccordions}
              setExpandedSettingsAccordions={setExpandedSettingsAccordions}
              pool={pool}
              noManagePermission={!checkPoolPermissions.data?.update_identity_pool}
            />
          )}

          {tab === "settings" && (
            <IdentityPoolConfiguration
              handleUpdate={handleUpdate}
              pool={pool}
              noManagePermission={!checkPoolPermissions.data?.update_identity_pool}
            />
          )}

          {tab === "schemas" && canListSchemas && (
            <IdentityPoolSchemas handleUpdate={handleUpdate} schemas={schemas} pool={pool} />
          )}

          {tab === "metadata" && <IdentityPoolMetadata poolId={id} handleUpdate={handleUpdate} />}
        </>
      )}
    </div>
  );
}

const Breadcrumb = ({ label, rootUrl }: { label: string; rootUrl: string }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      items={[
        {
          key: "list",
          label: "Identity Pools list",
          onClick: () => navigate(rootUrl),
        },
        { key: "detail", label },
      ]}
    />
  );
};
