import React from "react";

import { AxiosResponse } from "axios";

import {
  SelfUserWithDataV2,
  SelfUserWithDataV2AuthenticationMechanismsEnum,
} from "@cloudentity/acp-identityself";

import FormSection from "../FormSection";
import SelfServiceCredentialInfo from "./SelfServiceCredentialInfo";
import CredentialAccordion from "./SelfServiceCredentialInfoAccordion";
import SelfServiceCredentialVerificationCode from "./SelfServiceCredentialVerificationCode";
import SelfServiceCredentialsLinkedAccounts from "./SelfServiceCredentialsLinkedAccounts";
import { FactorType } from "./utils";

export interface SelfServiceCredentialsProps {
  userData: SelfUserWithDataV2 | null;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceCredentials({
  userData,
  fetchUser,
}: SelfServiceCredentialsProps) {
  const verifiableAddresses = userData?.verifiable_addresses ?? [];

  const federatedAccounts = userData?.federated_accounts ?? [];

  const allAuthenticationMechanisms = Object.values(
    SelfUserWithDataV2AuthenticationMechanismsEnum
  ).sort((a, b) => {
    const isFirstFactorA = userData?.authentication_mechanisms?.includes(a);
    const isFirstFactorB = userData?.authentication_mechanisms?.includes(b);
    const isSecondFactorA = userData?.second_factor_authentication_mechanisms?.includes(a as any);
    const isSecondFactorB = userData?.second_factor_authentication_mechanisms?.includes(b as any);

    return isFirstFactorB === isFirstFactorA
      ? Number(isSecondFactorB) - Number(isSecondFactorA)
      : Number(isFirstFactorB) - Number(isFirstFactorA);
  });

  const credentials = userData?.credentials ?? [];

  const preferredAuthenticationMechanisms: any = [
    userData?.preferred_authentication_mechanism,
    userData?.second_factor_preferred_authentication_mechanism,
  ];

  return (
    <>
      <FormSection
        id="sign-in-methods-header"
        title="Sign-in methods"
        subtitle="These are the methods used to sign-in to your account"
      />

      {allAuthenticationMechanisms.map(mechanism => {
        const credential = credentials.find(c => c.type === (mechanism as any));
        const isPreferred = preferredAuthenticationMechanisms.includes(mechanism);
        const isFirstFactor = userData?.authentication_mechanisms?.includes(mechanism);
        const isSecondFactor = userData?.second_factor_authentication_mechanisms?.includes(
          mechanism as any
        );
        const factorType =
          (isFirstFactor && FactorType["first-factor"]) ||
          (isSecondFactor && FactorType["second-factor"]) ||
          undefined;

        const multipleCredentialsOfType = credentials.filter(c => c.type === credential?.type);

        if (credential) {
          return (
            <SelfServiceCredentialInfo
              credential={credential}
              userData={userData}
              key={mechanism}
              fetchUser={fetchUser}
              isPreferred={isPreferred}
              factorType={factorType}
              multipleCredentialsOfType={multipleCredentialsOfType}
            />
          );
        } else if (
          mechanism === SelfUserWithDataV2AuthenticationMechanismsEnum.Otp &&
          verifiableAddresses.length
        ) {
          return (
            <CredentialAccordion
              type={mechanism}
              factorType={factorType}
              isPreferred={isPreferred}
              key={mechanism}
            >
              <SelfServiceCredentialVerificationCode addresses={verifiableAddresses} />
            </CredentialAccordion>
          );
        } else {
          return (
            <SelfServiceCredentialInfo
              key={mechanism}
              userData={userData}
              isEmptyType={mechanism}
              isPreferred={isPreferred}
              factorType={factorType}
              fetchUser={fetchUser}
            />
          );
        }
      })}

      <SelfServiceCredentialsLinkedAccounts federatedAccounts={federatedAccounts} />
    </>
  );
}
