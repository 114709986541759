import React from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import Progress from "../../../../../../../common/components/Progress";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import { useGetEnvironment } from "../../../../../../services/adminEnvironmentQuery";
import { useCheckPoolPermissions } from "../../../../../../services/adminIdentityPoolsQuery";
import { useGetSchema } from "../../../../../../services/adminIdentitySchemasQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../../../../../../services/adminPermissionsQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolUsers from "./IdentityPoolUsers";

interface Props {
  pool: PoolResponse;
}

export default function IdentityPoolUsersController({ pool }: Props) {
  const [workspace] = useWorkspace();

  const { mode: poolMode } = usePoolRootUrl();
  const getEnvironmentQuery = useGetEnvironment();
  const isRolesEnabled = useFeature("roles");
  const isWithRolesEnabled = useFeature("with_roles");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace, {
    enabled: poolMode === "workspace" && !!workspace,
  });
  const checkPoolPermissionsQuery = useCheckPoolPermissions(pool?.id!);
  const canListSchemas = !!checkTenantPermissionsQuery.data?.list_identity_pools;

  const payloadSchemaQuery = useGetSchema(pool.payload_schema_id, { enabled: canListSchemas });
  const metadataSchemaQuery = useGetSchema(pool.metadata_schema_id, { enabled: canListSchemas });
  const businessMetadataSchemaQuery = useGetSchema(pool.business_metadata_schema_id, {
    enabled: canListSchemas,
  });

  const isWorkspaceOrPoolsRolesVisible =
    poolMode === "workspace" &&
    isRolesEnabled &&
    isWithRolesEnabled &&
    (!!checkWorkspacePermissionsQuery.data?.read_roles ||
      !!checkPoolPermissionsQuery.data?.read_roles);

  const progress =
    payloadSchemaQuery.isFetching ||
    metadataSchemaQuery.isFetching ||
    businessMetadataSchemaQuery.isFetching ||
    checkTenantPermissionsQuery.isLoading ||
    checkWorkspacePermissionsQuery.isLoading ||
    checkPoolPermissionsQuery.isLoading ||
    getEnvironmentQuery.isLoading;

  return progress ? (
    <Progress isGlobalLoader />
  ) : (
    <IdentityPoolUsers
      pool={pool}
      payloadSchema={payloadSchemaQuery.data?.schema}
      metadataSchema={metadataSchemaQuery.data?.schema}
      businessMetadataSchema={businessMetadataSchemaQuery.data?.schema}
      isWorkspaceOrPoolsRolesVisible={isWorkspaceOrPoolsRolesVisible}
    />
  );
}
