import React, { useState } from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { IDP } from "@cloudentity/acp-root";

import DialogFullScreen from "../../../../../common/components/DialogFullScreen";
import Switch from "../../../../../common/components/Switch";
import { IdpType } from "../../../../services/adminIDPsApi";
import { useGetUserInfo } from "../../../../services/oauth2Query";
import Tooltip from "../../../common/Tooltip";
import IdentityPoolForDialog from "../../../workspaceDirectory/identityPools/identityPool/IdentityPoolForDialog";
import IdentitiesDetailsControllerForDialog from "../../IdentitiesDetailsControllerForDialog";
import ConfigureButton from "./ConfigureButton";

const useStyles = makeStyles()(theme => ({
  switchCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    padding: 16,
    position: "relative",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    background: "none",
    border: "solid 1px black",
    color: theme.palette.secondary.light,
    width: 30,
    height: 30,
    marginRight: 8,
  },
  avatarSvg: {
    svg: {
      width: 20,
    },
  },
  idpAvatar: {
    border: "none",
    width: 28,
    height: 28,
    "& > img": {
      width: "100%",
    },
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
}));

interface Props {
  name: string;
  icon: any;
  id: string;
  noSvgStyle?: boolean;
  idpAvatar?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  progress: string;
  cardId: string;
  configurePoolInDialog?: IDP;
  configureIDPInDialog?: IDP;
  configureLink?: string;
  disabled?: boolean;
  hideExtensions?: boolean;
}

export default function IdentitiesListSimpleSwitchCard({
  name,
  icon,
  id,
  noSvgStyle,
  idpAvatar,
  checked,
  onChange,
  progress,
  cardId,
  configurePoolInDialog,
  configureIDPInDialog,
  configureLink,
  disabled,
  hideExtensions,
}: Props) {
  const { cx, classes } = useStyles();
  const [configurationDialog, setConfigurationDialog] = useState(false);
  const [poolDialog, setPoolDialog] = useState<IDP | null>(null);

  const userinfoQuery = useGetUserInfo();
  const disabledIdpId = userinfoQuery.data?.idp;
  const navigate = useNavigate();

  const isProgress = progress === cardId;

  return (
    <Paper id={`switch-card-${id}`} className={classes.switchCard}>
      {isProgress && <LinearProgress className={classes.progress} />}
      <div className={classes.flexContainer}>
        <Avatar
          className={cx(
            classes.avatar,
            !noSvgStyle && classes.avatarSvg,
            idpAvatar && classes.idpAvatar
          )}
        >
          {icon}
        </Avatar>
        <Typography variant="h5">{name}</Typography>
      </div>

      <div className={classes.flexContainer}>
        {(configureLink || configureIDPInDialog || configurePoolInDialog) && (
          <div style={{ marginRight: 8 }}>
            <ConfigureButton
              name={name}
              onClick={() => {
                if (!!configurePoolInDialog) {
                  setPoolDialog(configurePoolInDialog);
                } else if (!!configureIDPInDialog) {
                  setConfigurationDialog(true);
                } else if (!!configureLink) {
                  navigate(configureLink);
                }
              }}
            />
          </div>
        )}

        <Tooltip
          title={disabledIdpId === id ? "Cannot disable IDP that was used to sign in" : undefined}
        >
          <span>
            <Switch
              checked={checked}
              onChange={(_, checked) => onChange(checked)}
              disabled={disabledIdpId === id || disabled || isProgress}
            />
          </span>
        </Tooltip>
      </div>

      {configurationDialog && configureIDPInDialog && (
        <DialogFullScreen
          id={`configure-${configureIDPInDialog.method}-dialog`}
          onCancel={() => setConfigurationDialog(false)}
          noPadding
        >
          <IdentitiesDetailsControllerForDialog
            id={configureIDPInDialog.id!}
            type={configureIDPInDialog.method as IdpType}
            serverId={configureIDPInDialog.authorization_server_id!}
            handleBack={() => setConfigurationDialog(false)}
            hideExtensions={hideExtensions}
          />
        </DialogFullScreen>
      )}
      {poolDialog && (
        <IdentityPoolForDialog
          poolId={poolDialog.identity_pool_id}
          serverId={poolDialog.authorization_server_id}
          onCancel={() => setPoolDialog(null)}
        />
      )}
    </Paper>
  );
}
