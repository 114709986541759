"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Cloudentity API
 * Cloudentity Identity API  It is important for clients to note that most APIs return the `ETag` response header. When executing API flows which modify and retrieve a given resource, clients are expected to follow `ETag` protocols by: 1. Caching the value of an `ETag` returned by the server when retrieving the resource, and 2. Supplying the `If-Match` header with the `ETag` value when attempting to modify the resource.  A `412 Precondition Failed` response will be returned if a write request is delayed after a sufficient length of time while a previous write request was being processed.  When interacting with APIs, make sure to always supply the If-Match header from the previous ETag header response for full consistency. Otherwise, the APIs may use the cached user data, and not return what you expect!  The POST APIs support idempotency for safely retrying requests without accidentally performing the same operation twice.  To use idempotent requests:  1. Generate a unique idempotency key (32-255 characters long). 2. Include the key in the X-Idempotency-Key header of your POST request. 3. Retry failed requests with the same key within 1 minute.  Responses for replayed requests will include the X-Idempotent-Replayed: true header.
 *
 * The version of the OpenAPI document: latest
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = exports.UsersApiFactory = exports.UsersApiFp = exports.UsersApiAxiosParamCreator = exports.OTPApi = exports.OTPApiFactory = exports.OTPApiFp = exports.OTPApiAxiosParamCreator = exports.MfaApi = exports.MfaApiFactory = exports.MfaApiFp = exports.MfaApiAxiosParamCreator = exports.SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum = exports.SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum = exports.SelfUserWithDataV2PreferredAuthenticationMechanismEnum = exports.SelfUserWithDataV2AuthenticationMechanismsEnum = exports.SelfUserVerifiableAddressTypeEnum = exports.SelfUserVerifiableAddressStatusEnum = exports.SelfUserVerifiableAddressPreferredContactMethodEnum = exports.SelfUserIdentifierTypeEnum = exports.SelfUserCredentialsTypeEnum = exports.SelfUserCredentialsStateEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var SelfUserCredentialsStateEnum;
(function (SelfUserCredentialsStateEnum) {
    SelfUserCredentialsStateEnum["Valid"] = "valid";
    SelfUserCredentialsStateEnum["MustBeReset"] = "must_be_reset";
    SelfUserCredentialsStateEnum["MustBeChanged"] = "must_be_changed";
})(SelfUserCredentialsStateEnum || (exports.SelfUserCredentialsStateEnum = SelfUserCredentialsStateEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserCredentialsTypeEnum;
(function (SelfUserCredentialsTypeEnum) {
    SelfUserCredentialsTypeEnum["Password"] = "password";
    SelfUserCredentialsTypeEnum["Webauthn"] = "webauthn";
    SelfUserCredentialsTypeEnum["Totp"] = "totp";
})(SelfUserCredentialsTypeEnum || (exports.SelfUserCredentialsTypeEnum = SelfUserCredentialsTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserIdentifierTypeEnum;
(function (SelfUserIdentifierTypeEnum) {
    SelfUserIdentifierTypeEnum["Email"] = "email";
    SelfUserIdentifierTypeEnum["Mobile"] = "mobile";
    SelfUserIdentifierTypeEnum["Uid"] = "uid";
    SelfUserIdentifierTypeEnum["External"] = "external";
    SelfUserIdentifierTypeEnum["Federated"] = "federated";
})(SelfUserIdentifierTypeEnum || (exports.SelfUserIdentifierTypeEnum = SelfUserIdentifierTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserVerifiableAddressPreferredContactMethodEnum;
(function (SelfUserVerifiableAddressPreferredContactMethodEnum) {
    SelfUserVerifiableAddressPreferredContactMethodEnum["Sms"] = "sms";
    SelfUserVerifiableAddressPreferredContactMethodEnum["Voice"] = "voice";
})(SelfUserVerifiableAddressPreferredContactMethodEnum || (exports.SelfUserVerifiableAddressPreferredContactMethodEnum = SelfUserVerifiableAddressPreferredContactMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserVerifiableAddressStatusEnum;
(function (SelfUserVerifiableAddressStatusEnum) {
    SelfUserVerifiableAddressStatusEnum["Active"] = "active";
    SelfUserVerifiableAddressStatusEnum["Inactive"] = "inactive";
})(SelfUserVerifiableAddressStatusEnum || (exports.SelfUserVerifiableAddressStatusEnum = SelfUserVerifiableAddressStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserVerifiableAddressTypeEnum;
(function (SelfUserVerifiableAddressTypeEnum) {
    SelfUserVerifiableAddressTypeEnum["Email"] = "email";
    SelfUserVerifiableAddressTypeEnum["Mobile"] = "mobile";
})(SelfUserVerifiableAddressTypeEnum || (exports.SelfUserVerifiableAddressTypeEnum = SelfUserVerifiableAddressTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserWithDataV2AuthenticationMechanismsEnum;
(function (SelfUserWithDataV2AuthenticationMechanismsEnum) {
    SelfUserWithDataV2AuthenticationMechanismsEnum["Totp"] = "totp";
    SelfUserWithDataV2AuthenticationMechanismsEnum["Password"] = "password";
    SelfUserWithDataV2AuthenticationMechanismsEnum["Otp"] = "otp";
    SelfUserWithDataV2AuthenticationMechanismsEnum["Webauthn"] = "webauthn";
})(SelfUserWithDataV2AuthenticationMechanismsEnum || (exports.SelfUserWithDataV2AuthenticationMechanismsEnum = SelfUserWithDataV2AuthenticationMechanismsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserWithDataV2PreferredAuthenticationMechanismEnum;
(function (SelfUserWithDataV2PreferredAuthenticationMechanismEnum) {
    SelfUserWithDataV2PreferredAuthenticationMechanismEnum["Totp"] = "totp";
    SelfUserWithDataV2PreferredAuthenticationMechanismEnum["Password"] = "password";
    SelfUserWithDataV2PreferredAuthenticationMechanismEnum["Otp"] = "otp";
    SelfUserWithDataV2PreferredAuthenticationMechanismEnum["Webauthn"] = "webauthn";
})(SelfUserWithDataV2PreferredAuthenticationMechanismEnum || (exports.SelfUserWithDataV2PreferredAuthenticationMechanismEnum = SelfUserWithDataV2PreferredAuthenticationMechanismEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum;
(function (SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum) {
    SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum["Totp"] = "totp";
    SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum["Password"] = "password";
    SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum["Otp"] = "otp";
    SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum["Webauthn"] = "webauthn";
})(SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum || (exports.SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum = SelfUserWithDataV2SecondFactorAuthenticationMechanismsEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum;
(function (SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum) {
    SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum["Totp"] = "totp";
    SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum["Password"] = "password";
    SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum["Otp"] = "otp";
    SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum["Webauthn"] = "webauthn";
})(SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum || (exports.SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum = SelfUserWithDataV2SecondFactorPreferredAuthenticationMechanismEnum = {}));
/**
 * MfaApi - axios parameter creator
 * @export
 */
var MfaApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete user MFA session
         * @param {string} mfaSessionID MFA session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMFASession: function (mfaSessionID_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([mfaSessionID_1], args_1, true), void 0, function (mfaSessionID, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mfaSessionID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteMFASession', 'mfaSessionID', mfaSessionID);
                            localVarPath = "/v2/self/mfa/sessions/{mfaSessionID}"
                                .replace("{".concat("mfaSessionID", "}"), encodeURIComponent(String(mfaSessionID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * user MFA sessions.
         * @summary Lists user MFA sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserMFASessions: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/mfa/sessions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["view_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MfaApiAxiosParamCreator = MfaApiAxiosParamCreator;
/**
 * MfaApi - functional programming interface
 * @export
 */
var MfaApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MfaApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Delete user MFA session
         * @param {string} mfaSessionID MFA session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMFASession: function (mfaSessionID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteMFASession(mfaSessionID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * user MFA sessions.
         * @summary Lists user MFA sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserMFASessions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUserMFASessions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MfaApiFp = MfaApiFp;
/**
 * MfaApi - factory interface
 * @export
 */
var MfaApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MfaApiFp)(configuration);
    return {
        /**
         *
         * @summary Delete user MFA session
         * @param {string} mfaSessionID MFA session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMFASession: function (mfaSessionID, options) {
            return localVarFp.deleteMFASession(mfaSessionID, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * user MFA sessions.
         * @summary Lists user MFA sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserMFASessions: function (options) {
            return localVarFp.listUserMFASessions(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MfaApiFactory = MfaApiFactory;
/**
 * MfaApi - object-oriented interface
 * @export
 * @class MfaApi
 * @extends {BaseAPI}
 */
var MfaApi = /** @class */ (function (_super) {
    __extends(MfaApi, _super);
    function MfaApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete user MFA session
     * @param {MfaApiDeleteMFASessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    MfaApi.prototype.deleteMFASession = function (requestParameters, options) {
        var _this = this;
        return (0, exports.MfaApiFp)(this.configuration).deleteMFASession(requestParameters.mfaSessionID, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * user MFA sessions.
     * @summary Lists user MFA sessions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    MfaApi.prototype.listUserMFASessions = function (options) {
        var _this = this;
        return (0, exports.MfaApiFp)(this.configuration).listUserMFASessions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MfaApi;
}(base_1.BaseAPI));
exports.MfaApi = MfaApi;
/**
 * OTPApi - axios parameter creator
 * @export
 */
var OTPApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Completes unverified address verification by checking if code is valid or not. Both address and code must be provided. Fails if address is not user\'s address or is user\'s verified or is someone\'s verified address. If the OTP is valid it is removed and a successful response is returned. Endpoint is protected by Brute Force mechanism.
         * @summary Complete Address Verification
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CompleteAddressVerification} [completeAddressVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAddressVerification: function (ifMatch_1, completeAddressVerification_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, completeAddressVerification_1], args_1, true), void 0, function (ifMatch, completeAddressVerification, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/self/address-verification/complete";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(completeAddressVerification, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.OTPApiAxiosParamCreator = OTPApiAxiosParamCreator;
/**
 * OTPApi - functional programming interface
 * @export
 */
var OTPApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.OTPApiAxiosParamCreator)(configuration);
    return {
        /**
         * Completes unverified address verification by checking if code is valid or not. Both address and code must be provided. Fails if address is not user\'s address or is user\'s verified or is someone\'s verified address. If the OTP is valid it is removed and a successful response is returned. Endpoint is protected by Brute Force mechanism.
         * @summary Complete Address Verification
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CompleteAddressVerification} [completeAddressVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAddressVerification: function (ifMatch, completeAddressVerification, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.completeAddressVerification(ifMatch, completeAddressVerification, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.OTPApiFp = OTPApiFp;
/**
 * OTPApi - factory interface
 * @export
 */
var OTPApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.OTPApiFp)(configuration);
    return {
        /**
         * Completes unverified address verification by checking if code is valid or not. Both address and code must be provided. Fails if address is not user\'s address or is user\'s verified or is someone\'s verified address. If the OTP is valid it is removed and a successful response is returned. Endpoint is protected by Brute Force mechanism.
         * @summary Complete Address Verification
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CompleteAddressVerification} [completeAddressVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAddressVerification: function (ifMatch, completeAddressVerification, options) {
            return localVarFp.completeAddressVerification(ifMatch, completeAddressVerification, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.OTPApiFactory = OTPApiFactory;
/**
 * OTPApi - object-oriented interface
 * @export
 * @class OTPApi
 * @extends {BaseAPI}
 */
var OTPApi = /** @class */ (function (_super) {
    __extends(OTPApi, _super);
    function OTPApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Completes unverified address verification by checking if code is valid or not. Both address and code must be provided. Fails if address is not user\'s address or is user\'s verified or is someone\'s verified address. If the OTP is valid it is removed and a successful response is returned. Endpoint is protected by Brute Force mechanism.
     * @summary Complete Address Verification
     * @param {OTPApiCompleteAddressVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTPApi
     */
    OTPApi.prototype.completeAddressVerification = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.OTPApiFp)(this.configuration).completeAddressVerification(requestParameters.ifMatch, requestParameters.completeAddressVerification, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OTPApi;
}(base_1.BaseAPI));
exports.OTPApi = OTPApi;
/**
 * UsersApi - axios parameter creator
 * @export
 */
var UsersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Begin WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Begin WebAuthn credentials generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginWebAuthnCredentialsGeneration: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/webauthn/create/begin";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (ifMatch_1, changePassword_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, changePassword_1], args_1, true), void 0, function (ifMatch, changePassword, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/self/change-password";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", [], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePassword, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordV2: function (ifMatch_1, changePassword_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, changePassword_1], args_1, true), void 0, function (ifMatch, changePassword, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/change-password";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePassword, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Changes user totp secret if provided totp code is valid.
         * @summary Change Totp Secret
         * @param {ChangeTotpSecret} [changeTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTotpSecret: function (changeTotpSecret_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([changeTotpSecret_1], args_1, true), void 0, function (changeTotpSecret, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/change-totp-secret";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changeTotpSecret, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Finish WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Finish WebAuthn credentials generation
         * @param {CredentialCreationResponse} [selfCompleteWebAuthnCreate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWebAuthnCredentialsGeneration: function (selfCompleteWebAuthnCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([selfCompleteWebAuthnCreate_1], args_1, true), void 0, function (selfCompleteWebAuthnCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/webauthn/create/complete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(selfCompleteWebAuthnCreate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Deletes WebAuthn key. NOTICE: it is forbidden to delete the last WebAuthn key.
         * @summary Delete WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebAuthnKey: function (webAuthnCredentialID_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([webAuthnCredentialID_1], args_1, true), void 0, function (webAuthnCredentialID, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'webAuthnCredentialID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteWebAuthnKey', 'webAuthnCredentialID', webAuthnCredentialID);
                            localVarPath = "/v2/self/webauthn/{webAuthnCredentialID}"
                                .replace("{".concat("webAuthnCredentialID", "}"), encodeURIComponent(String(webAuthnCredentialID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: function (ifMatch_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1], args_1, true), void 0, function (ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/self/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", [], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileV2: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["view_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set name for WebAuthn key
         * @summary Name WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {NameWebAuthnKey} [nameWebAuthnKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nameWebAuthnKey: function (webAuthnCredentialID_1, nameWebAuthnKey_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([webAuthnCredentialID_1, nameWebAuthnKey_1], args_1, true), void 0, function (webAuthnCredentialID, nameWebAuthnKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'webAuthnCredentialID' is not null or undefined
                            (0, common_1.assertParamExists)('nameWebAuthnKey', 'webAuthnCredentialID', webAuthnCredentialID);
                            localVarPath = "/v2/self/webauthn/{webAuthnCredentialID}/name"
                                .replace("{".concat("webAuthnCredentialID", "}"), encodeURIComponent(String(webAuthnCredentialID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(nameWebAuthnKey, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Resets password for user if the provided OTP is valid. It\'s the second and final step of the flow to reset the password. Either user identifier or extended code must be provided. Endpoint returns generic `401` regardless of the reason of failure to prevent email/mobile enumeration. After a successful password reset, OTP gets invalidated, so it cannot be reused. Endpoint is protected by Brute Force mechanism.
         * @summary Confirm Reset Password
         * @param {string} ipID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ResetPasswordConfirm} [resetPasswordConfirm]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirm: function (ipID_1, ifMatch_1, resetPasswordConfirm_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ipID_1, ifMatch_1, resetPasswordConfirm_1], args_1, true), void 0, function (ipID, ifMatch, resetPasswordConfirm, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'ipID' is not null or undefined
                    (0, common_1.assertParamExists)('resetPasswordConfirm', 'ipID', ipID);
                    localVarPath = "/public/pools/{ipID}/reset-password/confirm"
                        .replace("{".concat("ipID", "}"), encodeURIComponent(String(ipID)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (ifMatch !== undefined && ifMatch !== null) {
                        localVarHeaderParameter['if-match'] = String(ifMatch);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordConfirm, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Set a password for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetPassword} [setPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: function (ifMatch_1, setPassword_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, setPassword_1], args_1, true), void 0, function (ifMatch, setPassword, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/set-password";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setPassword, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set totp secret for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Totp Secret
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetTotpSecret} [setTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotpSecret: function (ifMatch_1, setTotpSecret_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, setTotpSecret_1], args_1, true), void 0, function (ifMatch, setTotpSecret, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/set-totp-secret";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setTotpSecret, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Set WebAuthn for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set WebAuthn
         * @param {SetWebAuthn} [setWebAuthnSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebAuthn: function (setWebAuthnSecret_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([setWebAuthnSecret_1], args_1, true), void 0, function (setWebAuthnSecret, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/set-webauthn";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setWebAuthnSecret, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: function (ifMatch_1, updateUser_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, updateUser_1], args_1, true), void 0, function (ifMatch, updateUser, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/self/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", [], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateUser, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileV2: function (ifMatch_1, updateUser_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, updateUser_1], args_1, true), void 0, function (ifMatch, updateUser, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/v2/self/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication self required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "self", ["manage_ss_profile"], configuration)];
                        case 1:
                            // authentication self required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateUser, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersApiAxiosParamCreator = UsersApiAxiosParamCreator;
/**
 * UsersApi - functional programming interface
 * @export
 */
var UsersApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UsersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Begin WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Begin WebAuthn credentials generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginWebAuthnCredentialsGeneration: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.beginWebAuthnCredentialsGeneration(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (ifMatch, changePassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changePassword(ifMatch, changePassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordV2: function (ifMatch, changePassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changePasswordV2(ifMatch, changePassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Changes user totp secret if provided totp code is valid.
         * @summary Change Totp Secret
         * @param {ChangeTotpSecret} [changeTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTotpSecret: function (changeTotpSecret, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changeTotpSecret(changeTotpSecret, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Finish WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Finish WebAuthn credentials generation
         * @param {CredentialCreationResponse} [selfCompleteWebAuthnCreate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWebAuthnCredentialsGeneration: function (selfCompleteWebAuthnCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.completeWebAuthnCredentialsGeneration(selfCompleteWebAuthnCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Deletes WebAuthn key. NOTICE: it is forbidden to delete the last WebAuthn key.
         * @summary Delete WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebAuthnKey: function (webAuthnCredentialID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteWebAuthnKey(webAuthnCredentialID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: function (ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserProfile(ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileV2: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserProfileV2(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set name for WebAuthn key
         * @summary Name WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {NameWebAuthnKey} [nameWebAuthnKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nameWebAuthnKey: function (webAuthnCredentialID, nameWebAuthnKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.nameWebAuthnKey(webAuthnCredentialID, nameWebAuthnKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Resets password for user if the provided OTP is valid. It\'s the second and final step of the flow to reset the password. Either user identifier or extended code must be provided. Endpoint returns generic `401` regardless of the reason of failure to prevent email/mobile enumeration. After a successful password reset, OTP gets invalidated, so it cannot be reused. Endpoint is protected by Brute Force mechanism.
         * @summary Confirm Reset Password
         * @param {string} ipID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ResetPasswordConfirm} [resetPasswordConfirm]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirm: function (ipID, ifMatch, resetPasswordConfirm, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.resetPasswordConfirm(ipID, ifMatch, resetPasswordConfirm, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set a password for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetPassword} [setPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: function (ifMatch, setPassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setPassword(ifMatch, setPassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set totp secret for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Totp Secret
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetTotpSecret} [setTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotpSecret: function (ifMatch, setTotpSecret, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setTotpSecret(ifMatch, setTotpSecret, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set WebAuthn for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set WebAuthn
         * @param {SetWebAuthn} [setWebAuthnSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebAuthn: function (setWebAuthnSecret, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setWebAuthn(setWebAuthnSecret, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: function (ifMatch, updateUser, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserProfile(ifMatch, updateUser, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileV2: function (ifMatch, updateUser, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserProfileV2(ifMatch, updateUser, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UsersApiFp = UsersApiFp;
/**
 * UsersApi - factory interface
 * @export
 */
var UsersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UsersApiFp)(configuration);
    return {
        /**
         * Begin WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Begin WebAuthn credentials generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginWebAuthnCredentialsGeneration: function (options) {
            return localVarFp.beginWebAuthnCredentialsGeneration(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (ifMatch, changePassword, options) {
            return localVarFp.changePassword(ifMatch, changePassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Changes user password if provided password matches current user password.
         * @summary Change Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ChangePassword} [changePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordV2: function (ifMatch, changePassword, options) {
            return localVarFp.changePasswordV2(ifMatch, changePassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Changes user totp secret if provided totp code is valid.
         * @summary Change Totp Secret
         * @param {ChangeTotpSecret} [changeTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTotpSecret: function (changeTotpSecret, options) {
            return localVarFp.changeTotpSecret(changeTotpSecret, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Finish WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
         * @summary Finish WebAuthn credentials generation
         * @param {CredentialCreationResponse} [selfCompleteWebAuthnCreate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWebAuthnCredentialsGeneration: function (selfCompleteWebAuthnCreate, options) {
            return localVarFp.completeWebAuthnCredentialsGeneration(selfCompleteWebAuthnCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Deletes WebAuthn key. NOTICE: it is forbidden to delete the last WebAuthn key.
         * @summary Delete WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebAuthnKey: function (webAuthnCredentialID, options) {
            return localVarFp.deleteWebAuthnKey(webAuthnCredentialID, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: function (ifMatch, options) {
            return localVarFp.getUserProfile(ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
         * @summary Self Get User Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileV2: function (options) {
            return localVarFp.getUserProfileV2(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set name for WebAuthn key
         * @summary Name WebAuthn key
         * @param {string} webAuthnCredentialID WebAuthn Credential ID
         * @param {NameWebAuthnKey} [nameWebAuthnKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nameWebAuthnKey: function (webAuthnCredentialID, nameWebAuthnKey, options) {
            return localVarFp.nameWebAuthnKey(webAuthnCredentialID, nameWebAuthnKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Resets password for user if the provided OTP is valid. It\'s the second and final step of the flow to reset the password. Either user identifier or extended code must be provided. Endpoint returns generic `401` regardless of the reason of failure to prevent email/mobile enumeration. After a successful password reset, OTP gets invalidated, so it cannot be reused. Endpoint is protected by Brute Force mechanism.
         * @summary Confirm Reset Password
         * @param {string} ipID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ResetPasswordConfirm} [resetPasswordConfirm]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirm: function (ipID, ifMatch, resetPasswordConfirm, options) {
            return localVarFp.resetPasswordConfirm(ipID, ifMatch, resetPasswordConfirm, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set a password for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Password
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetPassword} [setPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: function (ifMatch, setPassword, options) {
            return localVarFp.setPassword(ifMatch, setPassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set totp secret for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set Totp Secret
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SetTotpSecret} [setTotpSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotpSecret: function (ifMatch, setTotpSecret, options) {
            return localVarFp.setTotpSecret(ifMatch, setTotpSecret, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set WebAuthn for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
         * @summary Set WebAuthn
         * @param {SetWebAuthn} [setWebAuthnSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebAuthn: function (setWebAuthnSecret, options) {
            return localVarFp.setWebAuthn(setWebAuthnSecret, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: function (ifMatch, updateUser, options) {
            return localVarFp.updateUserProfile(ifMatch, updateUser, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
         * @summary Self Update User Profile
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {SelfUserUpdate} [updateUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileV2: function (ifMatch, updateUser, options) {
            return localVarFp.updateUserProfileV2(ifMatch, updateUser, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UsersApiFactory = UsersApiFactory;
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
var UsersApi = /** @class */ (function (_super) {
    __extends(UsersApi, _super);
    function UsersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Begin WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
     * @summary Begin WebAuthn credentials generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.beginWebAuthnCredentialsGeneration = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).beginWebAuthnCredentialsGeneration(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Changes user password if provided password matches current user password.
     * @summary Change Password
     * @param {UsersApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.changePassword = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).changePassword(requestParameters.ifMatch, requestParameters.changePassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Changes user password if provided password matches current user password.
     * @summary Change Password
     * @param {UsersApiChangePasswordV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.changePasswordV2 = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).changePasswordV2(requestParameters.ifMatch, requestParameters.changePassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Changes user totp secret if provided totp code is valid.
     * @summary Change Totp Secret
     * @param {UsersApiChangeTotpSecretRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.changeTotpSecret = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).changeTotpSecret(requestParameters.changeTotpSecret, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Finish WebAuthn credentials generation  This API requires authentication to happen within the last 5 minutes.
     * @summary Finish WebAuthn credentials generation
     * @param {UsersApiCompleteWebAuthnCredentialsGenerationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.completeWebAuthnCredentialsGeneration = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).completeWebAuthnCredentialsGeneration(requestParameters.selfCompleteWebAuthnCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Deletes WebAuthn key. NOTICE: it is forbidden to delete the last WebAuthn key.
     * @summary Delete WebAuthn key
     * @param {UsersApiDeleteWebAuthnKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteWebAuthnKey = function (requestParameters, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteWebAuthnKey(requestParameters.webAuthnCredentialID, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
     * @summary Self Get User Profile
     * @param {UsersApiGetUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getUserProfile = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).getUserProfile(requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns base view on user entry. Besides basic user entry it returns all user identifiers and addresses. Also returns user metadata (only fields not marked as hidden) and payload.
     * @summary Self Get User Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getUserProfileV2 = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getUserProfileV2(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set name for WebAuthn key
     * @summary Name WebAuthn key
     * @param {UsersApiNameWebAuthnKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.nameWebAuthnKey = function (requestParameters, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).nameWebAuthnKey(requestParameters.webAuthnCredentialID, requestParameters.nameWebAuthnKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Resets password for user if the provided OTP is valid. It\'s the second and final step of the flow to reset the password. Either user identifier or extended code must be provided. Endpoint returns generic `401` regardless of the reason of failure to prevent email/mobile enumeration. After a successful password reset, OTP gets invalidated, so it cannot be reused. Endpoint is protected by Brute Force mechanism.
     * @summary Confirm Reset Password
     * @param {UsersApiResetPasswordConfirmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.resetPasswordConfirm = function (requestParameters, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).resetPasswordConfirm(requestParameters.ipID, requestParameters.ifMatch, requestParameters.resetPasswordConfirm, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set a password for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
     * @summary Set Password
     * @param {UsersApiSetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.setPassword = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).setPassword(requestParameters.ifMatch, requestParameters.setPassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set totp secret for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
     * @summary Set Totp Secret
     * @param {UsersApiSetTotpSecretRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.setTotpSecret = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).setTotpSecret(requestParameters.ifMatch, requestParameters.setTotpSecret, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set WebAuthn for a user who doesn\'t have one yet  This API requires authentication to happen within the last 5 minutes.
     * @summary Set WebAuthn
     * @param {UsersApiSetWebAuthnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.setWebAuthn = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).setWebAuthn(requestParameters.setWebAuthnSecret, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
     * @summary Self Update User Profile
     * @param {UsersApiUpdateUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.updateUserProfile = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).updateUserProfile(requestParameters.ifMatch, requestParameters.updateUser, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates user payload. Payload must be valid against schema defined in user entry.  Returns base view on user entry (see Self Get User Profile endpoint).
     * @summary Self Update User Profile
     * @param {UsersApiUpdateUserProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.updateUserProfileV2 = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.UsersApiFp)(this.configuration).updateUserProfileV2(requestParameters.ifMatch, requestParameters.updateUser, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UsersApi;
}(base_1.BaseAPI));
exports.UsersApi = UsersApi;
