import { useContext } from "react";

import { Environment } from "@cloudentity/acp-admin";

import { GlobalStoreContext } from "../../../admin/GlobalStore/GlobalStore";
import { useOrganization } from "../../../admin/components/common/useOrganization";
import { useWorkspace } from "../../../admin/components/common/useWorkspace";
import {
  useGetEnvironment,
  useGetWorkspaceEnvironment,
} from "../../../admin/services/adminEnvironmentQuery";
import { useCheckTenantPermissions } from "../../../admin/services/adminPermissionsQuery";

export type UseFeatureFn = (flagName: keyof Environment) => boolean;

export const useFeature: UseFeatureFn = flagName => {
  const globalStoreContext = useContext(GlobalStoreContext);
  const getEnvironmentQuery = useGetEnvironment({
    enabled: globalStoreContext.authorization.authorized,
  });

  return Boolean(getEnvironmentQuery.data?.[flagName]);
};

export const useFeatureWithWorkspaceEnv: UseFeatureFn = flagName => {
  const [workspace] = useWorkspace();
  const [organizationId] = useOrganization();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const getWorkspaceEnvironment = useGetWorkspaceEnvironment(workspace || organizationId, {
    enabled:
      !!(workspace || organizationId) &&
      checkTenantPermissionsQuery.isSuccess &&
      !checkTenantPermissionsQuery.data?.read_system_environment,
  });

  return useFeature(flagName) || Boolean(getWorkspaceEnvironment.data?.[flagName]) || false;
};

export const useFeatureIsOrganizationsEnabled = () => {
  return useFeatureWithWorkspaceEnv("organizations");
};
