import { getTenantId } from "../../../../common/api/paths";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import { useListTenantRoles } from "../../../services/adminRolesQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";

interface Props {
  identityPoolId: string;
  disabled?: boolean;
}

export const useTenantRoles = (props: Props) => {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const hasReadRolesPermission = checkTenantPermissionsQuery.data?.read_roles;

  const idpsQuery = useListIDPs(getTenantId(), "admin");
  const isConnectedToBuildInAdminPool = !!(idpsQuery.data?.idps || []).find(
    idp => idp.identity_pool_id === props.identityPoolId
  );

  const isRolesEnabled = useFeature("roles");
  const isWithRolesEnabled = useFeature("with_roles");

  const isTenantRolesEnabled = !!(
    hasReadRolesPermission &&
    isRolesEnabled &&
    isWithRolesEnabled &&
    isConnectedToBuildInAdminPool &&
    !props?.disabled
  );

  const query = useListTenantRoles({ enabled: isTenantRolesEnabled });

  return {
    query,
    isEnabled: isTenantRolesEnabled,
    isResolved: idpsQuery.isSuccess && (isTenantRolesEnabled ? query.isSuccess : true),
  };
};
