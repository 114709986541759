import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import { notifyErrorOrDefaultTo } from "../../../../../common/components/notifications/notificationService";
import identityPoolsApi from "../../../../services/adminIdentityPoolsApi";
import {
  listPoolsQueryKey,
  listUserWorkspacePoolsQueryKey,
  listWorkspacePoolsQueryKey,
  useListPools,
} from "../../../../services/adminIdentityPoolsQuery";
import { addDefaultPreferredAuthMechanism } from "../utils";
import IdentityPoolsCreateView, { CreatePoolFormDataType } from "./IdentityPoolsCreateView";

export interface IdentityPoolsCreateProps {
  onCreated: (pool: PoolResponse) => void;
  onClose: () => void;
  workspaceIdForNewPool?: string;
}

export default function IdentityPoolsCreate({
  onClose,
  onCreated,
  workspaceIdForNewPool,
}: IdentityPoolsCreateProps) {
  const [progress, setProgress] = useState(false);

  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const poolsQuery = useListPools({ tid: tenantId, limit: 100 });

  const pools = poolsQuery.data?.pools ?? [];

  const handleCreate = (newPool: CreatePoolFormDataType) => {
    setProgress(true);

    let createdPool: PoolResponse;

    const payload = addDefaultPreferredAuthMechanism(newPool);

    const pool = {
      ...newPool,
      name: payload.name?.trim(),
      description: payload.description?.trim(),
      tenant_id: tenantId,
    };

    const apiPromise = workspaceIdForNewPool
      ? identityPoolsApi.createWorkspacePool({ wid: workspaceIdForNewPool, pool })
      : identityPoolsApi.createPool({ pool });

    apiPromise
      .then(res => {
        createdPool = res.data;
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(tenantId) }))
      .then(() => {
        if (workspaceIdForNewPool) {
          queryClient.invalidateQueries({
            queryKey: listUserWorkspacePoolsQueryKey(workspaceIdForNewPool),
          });
          queryClient.invalidateQueries({
            queryKey: listWorkspacePoolsQueryKey(workspaceIdForNewPool),
          });
        }
      })
      .then(() => onCreated(createdPool))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to create identity pool"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <IdentityPoolsCreateView
      handleCreate={handleCreate}
      handleClose={onClose}
      progress={progress}
      poolNames={pools.map(pool => pool.name)}
    />
  );
}
