import React, { ReactNode, useState } from "react";

import AdsClick from "@mui/icons-material/AdsClick";
import Typography from "@mui/material/Typography";
import Lottie from "lottie-react";
import { CheckCircle, ChevronDown, ChevronUp, Zap } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Button from "../../../../common/components/Button";
import heroAnimation from "../img/CIAM-Illustration-Hero.lottie.json";
import introGraphic from "../img/welcome-rings.svg";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";

const useStyles = makeStyles()(theme => ({
  conatiner: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  left: {
    padding: 32,
    flex: 1,
  },
  right: {
    "& > div": {
      height: "100%",
    },
    "@media (max-width: 1060px)": {
      display: "none",
    },
  },
  card: {
    position: "relative",
    width: "100%",
    margin: "32px 0 12px",
    borderRadius: 4,
    border: "solid 1px",
    borderColor: theme.custom.sa.greys.g40,
    backgroundColor: "white",
    color: theme.palette.secondary.light,
  },
  content: {
    display: "flex",
    gap: 32,
  },
  img: {
    width: 200,
    objectFit: "cover",
  },
  list: {
    padding: "0 0 16px",
    margin: 0,
    "& > li": {
      listStyle: "none",
      margin: "4px 16px",
      display: "flex",
    },
  },
  info: {
    display: "flex",
    gap: 24,
    borderBottom: `solid 1px ${theme.custom.greys.border}`,
    background:
      "radial-gradient(156.43% 482.44% at 23% -286.06%, #FFEB3C 45.02%, #C3F380 61.68%, #4394F7 100%)",
    borderRadius: "16px 16px 0 0",
    padding: 16,
    color: theme.custom.sa.brand.high,
  },
  paragraph: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    margin: "8px 0 0",
    padding: 0,
  },
  infoBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    padding: 10,
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.custom.sa.greys.g40,
    background: theme.custom.sa.greys.g0,
    boxShadow: theme.custom.sa.shadows.s600,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    backgroundColor: theme.custom.sa.neutrals.n10,
    borderRadius: "0 0 4px 4px",
  },
  check: {
    marginRight: 12,
    marginTop: 4,
    color: theme.custom.sa.complimentary.green.high,
    display: "block",
  },
  introHello: {
    marginTop: 24,
  },
}));

interface Props {
  firstName: string;
  onChangeStep: (step: Steps) => void;
}

export default function WelcomeStep({ firstName, onChangeStep }: Props) {
  const { classes } = useStyles();
  const { cx, classes: commonClasses } = useCommonStyles();
  const [openAdditional, setOpenAdditional] = useState(false);

  return (
    <div className={classes.conatiner}>
      <div className={classes.left}>
        <div className={commonClasses.intro}>
          <Typography variant="h2" className={commonClasses.header}>
            Welcome to SecureAuth admin portal!
          </Typography>
          <p className={classes.introHello}>
            <b>Hello{firstName ? ` ${firstName} ` : " "}</b>👋
          </p>
          <SmallInfoText fontSize={14}>
            This is your centralized platform for managing identity and access control for users and
            applications throughout your digital ecosystem.
          </SmallInfoText>
        </div>
        <Card
          title="Modern authentication experiences for your business applications"
          content={
            <ul className={classes.list}>
              <ListItem>Enhanced User Experience - Frictionless & passwordless logins </ListItem>
              <ListItem>
                Strengthened Security with SSO, Passkeys, adaptive multi factor authentication
              </ListItem>
              <ListItem>Migrate to modern authentication from legacy implementations</ListItem>
              <ListItem>Simplified management for administrators & user self service</ListItem>
              <ListItem>Reduced IT costs for password resets & access issues</ListItem>
            </ul>
          }
          footer={
            <>
              <Button variant="text">Skip to Overview</Button>
              <Button variant="contained" onClick={() => onChangeStep(Steps.MAIN_USE_CASE_SIGN_IN)}>
                Continue
              </Button>
            </>
          }
        >
          <div className={classes.info}>
            <div className={classes.infoBoxIcon}>
              <AdsClick />
            </div>
            <div>
              <Typography variant="h4">
                Try our sample web application (Blixit Application)
              </Typography>

              <p className={classes.paragraph}>
                <Zap size={14} />
                You'll see how easy it is to configure authentication options, invite users, and let
                them try our demo application.
              </p>
              <p className={classes.paragraph}>
                <Zap size={14} />
                Once you're impressed with the experience, you can seamlessly integrate it into your
                own business applications!
              </p>
            </div>
          </div>
        </Card>

        <div className={commonClasses.additionalSection}>
          <Button
            variant="text"
            className={commonClasses.buttonExplore}
            onClick={() => setOpenAdditional(isOpen => !isOpen)}
          >
            <Typography variant="textSM">Explore additional capabilities</Typography>
            {openAdditional ? (
              <ChevronUp size={16} style={{ marginLeft: 4 }} />
            ) : (
              <ChevronDown size={16} style={{ marginLeft: 4 }} />
            )}
          </Button>
          {openAdditional && (
            <div>
              <SmallInfoText>
                The SecureAuth platform can also address advanced use cases such as Open Finance
                security and data compliance, as well as delegated user and organization management
                for partner and B2B applications.
              </SmallInfoText>
              <Card
                title="Open Finance Security and Compliance"
                content={
                  <ul className={classes.list}>
                    <ListItem>
                      OpenID Certified Financial-Grade API (FAPI) Security provider
                    </ListItem>
                    <ListItem>
                      Use for Open Banking, Finance, Healthcare, Energy, and Telecom API security
                    </ListItem>
                    <ListItem>Advanced API authorization standards and mechanisms</ListItem>
                    <ListItem>
                      User data sharing consent management with third party applications
                    </ListItem>
                    <ListItem>
                      One click integration with your API Gateways & Identity Providers
                    </ListItem>
                    <ListItem>
                      Plug and play compliance with frameworks like FDX, CDR, PSD2, OB UK
                    </ListItem>
                  </ul>
                }
                footer={
                  <>
                    <div />
                    <Button variant="contained">Continue</Button>
                  </>
                }
              />
              <Card
                title="Partner and B2B IAM"
                content={
                  <ul className={classes.list}>
                    <ListItem>
                      Model your partner / B2B customer relations as organizations
                    </ListItem>
                    <ListItem>
                      Enterprise SSO & modern authentication for organization users
                    </ListItem>
                    <ListItem>
                      Delegated administration for enterprise SSO setup & user management
                    </ListItem>
                    <ListItem>
                      Fine-grained access control of applications by organization users
                    </ListItem>
                    <ListItem>Reduce IT overhead for organization and user management</ListItem>
                  </ul>
                }
                footer={
                  <>
                    <div />
                    <Button variant="contained">Continue</Button>
                  </>
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className={cx(commonClasses.right, classes.right)}>
        <Lottie animationData={heroAnimation} />
      </div>
    </div>
  );
}

function ListItem({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return (
    <li>
      <CheckCircle size={18} className={classes.check} />
      {children}
    </li>
  );
}

function Card({
  title,
  content,
  children,
  footer,
}: {
  title?: ReactNode;
  content: ReactNode;
  children?: ReactNode;
  footer: ReactNode;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.content}>
        <img src={introGraphic} alt="intro graphic" className={classes.img} />
        <div style={{ flex: 1 }}>
          <Typography variant="textLG" style={{ margin: 16 }} component="div">
            <b>{title}</b>
          </Typography>

          {content}
        </div>
      </div>
      {children}
      <div className={classes.footer}>{footer}</div>
    </div>
  );
}
