import React from "react";

import Dialog from "../../../../common/components/Dialog";
import Progress from "../../../../common/components/Progress";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { useOrganizationMutation } from "../../../services/adminOrganizationsMutations";
import { useGetOrganization } from "../../../services/adminOrganizationsQuery";

interface Props {
  serverID: string;
  onClose: () => void;
  isOrganization?: boolean;
}

export default function EditOrganizationDialog({ serverID, onClose }: Props) {
  const getOrganizationQuery = useGetOrganization(serverID);
  const organizationMutation = useOrganizationMutation({ wid: serverID });

  const form = useForm({
    id: "edit-organization",
    initialValues: getOrganizationQuery.data,
    progress: organizationMutation.isPending,
  });

  const handleUpdate = data => {
    const d = { ...getOrganizationQuery.data, ...data };

    organizationMutation
      .mutateAsync({ wid: serverID, org: d })
      .then(() => {
        notifySuccess("Organization settings saved successfully");
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update organization settings"));
  };

  return (
    <Dialog onClose={onClose} id="edit-organization-dialog" title="General Settings">
      <Form form={form}>
        {getOrganizationQuery.isLoading ? (
          <div style={{ height: 169 }}>
            <Progress />
          </div>
        ) : (
          <>
            <TextFieldRequired name="name" label="Organization name" />
            <FormFooter onCancel={onClose} onSubmit={handleUpdate} />
          </>
        )}
      </Form>
    </Dialog>
  );
}
