import { useQuery } from "@tanstack/react-query";

import { Tenant, TenantPermissionsResponse } from "@cloudentity/acp-admin";
import { SecureOptions } from "@cloudentity/acp-root";

import adminTenantsApi from "./adminTenantsApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const GET_TENANT_QUERY = ["GET_TENANT_QUERY"];
const GET_TENANTS_QUERY = ["GET_TENANTS_QUERY"];
const CHECK_TENANT_PERMISSIONS = ["CHECK_TENANT_PERMISSIONS"];

const GET_TENANT_SECURITY_QUERY = ["GET_TENANT_SECURITY_QUERY"];
export const GET_SECURITY_QUERY = ["GET_SECURITY_QUERY"];

export const getTenantQueryKey = tid => [...GET_TENANT_QUERY, tid];
export const getTenantSecurityQueryKey = tid => [...GET_TENANT_SECURITY_QUERY, tid];

export const useGetTenant = (tid, options?) =>
  useQueryWithTenantPermissionCheck<Tenant>(
    "get_tenant",
    getTenantQueryKey(tid),
    withQueryError<Tenant>(async () => {
      const data = await adminTenantsApi.getTenant({});
      return data.data;
    }, "Error occurred while trying to fetch tenant"),
    options
  );

export const useGetTenants = () =>
  useQuery({
    queryKey: GET_TENANTS_QUERY,
    queryFn: withQueryError(async () => {
      const response = await adminTenantsApi.listTenants();
      return response.data.tenants;
    }, "Error occurred while trying to fetch tenants"),
  });

export const useCheckTenantPermissions = (options?: any) =>
  useQuery<TenantPermissionsResponse>({
    queryKey: CHECK_TENANT_PERMISSIONS,
    queryFn: withQueryError(async () => {
      const response = await adminTenantsApi.checkTenantPermissions({});
      return response.data;
    }, "Error occurred while trying to check tenant permissions"),
    ...options,
  });

export const useGetTenantSecurity = (tid: string) =>
  useQuery({
    queryKey: getTenantSecurityQueryKey(tid),
    queryFn: withQueryError<SecureOptions>(async () => {
      const response = await adminTenantsApi.getTenantSecurity(tid);
      return response.data;
    }, "Error occurred while trying to fetch tenant security configuration"),
  });

export const useGetSecurity = () =>
  useQuery({
    queryKey: GET_SECURITY_QUERY,
    queryFn: withQueryError<SecureOptions>(async () => {
      const response = await adminTenantsApi.getSecurity();
      return response.data;
    }, "Error occurred while trying to fetch security configuration"),
  });
