import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import PageContent from "../../../admin/components/common/PageContent";
import PageHeader from "../../../admin/components/common/PageHeader";
import { useOrganization } from "../../../admin/components/common/useOrganization";
import OrganizationBreadcrumb from "../../../admin/components/organizations/OrganizationBreadcrumb";
import Suborganizations from "../../../admin/components/organizations/Suborganizations";
import { B2B_ORGANIZATION_GRID_MODE_KEY } from "../../../admin/components/workspaceDirectory/organizations/utils";
import { useGetOrganization } from "../../../admin/services/adminOrganizationsQuery";
import B2BAddOrganization from "../../directory/B2BAddOrganization";

export default function B2BSuborganizations() {
  const navigate = useNavigate();

  const [organizationId] = useOrganization();
  const [createDialog, setCreateDialog] = useState<{ parentId?: string } | null>(null);

  const getOrganizationQuery = useGetOrganization(organizationId);

  const handleSelect = (id: string) => {
    navigate(`/organizations/${id}/suborganizations`);
  };

  return (
    <div>
      <PageHeader
        title="Suborganizations"
        progress={getOrganizationQuery.isLoading}
        breadcrumb={
          <OrganizationBreadcrumb
            name={getOrganizationQuery.data?.name || getOrganizationQuery.data?.id || "..."}
            parentId={getOrganizationQuery.data?.parent_id || ""}
            parentUrl={parentId => `/organizations/${parentId}/suborganizations`}
          />
        }
      />
      <PageContent>
        <Suborganizations
          title="No suborganizations"
          description="This organization does not have child organizations yet."
          parentServer={getOrganizationQuery.data}
          gridModeLocalStorageKey={B2B_ORGANIZATION_GRID_MODE_KEY(
            getOrganizationQuery.data?.id || ""
          )}
          onSelect={handleSelect}
          onCreate={parentId => setCreateDialog({ parentId })}
        />

        {createDialog && (
          <B2BAddOrganization
            onCancel={() => setCreateDialog(null)}
            onSkip={() => setCreateDialog(null)}
            onInviteSent={() => setCreateDialog(null)}
            organizationParentId={createDialog.parentId || getOrganizationQuery.data?.id}
          />
        )}
      </PageContent>
    </div>
  );
}
