import React, { useState } from "react";
import { useParams } from "react-router";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import {
  Pool,
  PoolAuthenticationMechanismsEnum,
  PoolPreferredAuthenticationMechanismEnum,
  PoolResponse,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import IconTooltip from "../../../../common/components/IconTooltip";
import { notifySuccess } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import {
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
} from "../../../services/adminIDPsQuery";
import identityPoolsApi from "../../../services/adminIdentityPoolsApi";
import {
  listUserWorkspacePoolsQueryKey,
  listWorkspacePoolsQueryKey,
} from "../../../services/adminIdentityPoolsQuery";

const useStyles = makeStyles()(theme => ({
  titleWithTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  openTooltip: {
    backgroundColor: "#CFE9FF",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  onCreated: (pool: PoolResponse) => void;
  onClose: () => void;
  workspaceId?: string;
  mode?: "b2b" | "admin";
}

export default function AddTeamDialog({ onCreated, onClose, workspaceId, mode = "b2b" }: Props) {
  const { id } = useParams<{ id: string }>();
  const { cx, classes } = useStyles();
  const [progress, setProgress] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const form = useForm({ id: "add-team", progress });
  const queryClient = useQueryClient();

  const handleCreatePool = data => {
    const pool: Pool = {
      name: data.name.trim(),
      description: data.description?.trim(),
      tenant_id: getTenantId(),
      authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
      preferred_authentication_mechanism: PoolPreferredAuthenticationMechanismEnum.Password,
      workspace_id: workspaceId || id,
      identifier_case_insensitive: true,
      metadata_schema_id: "organizations_pool_default_metadata",
      payload_schema_id: "organizations_pool_default_payload",
    };

    setProgress(true);
    let createdPool: PoolResponse;
    return identityPoolsApi
      .createWorkspacePool({ wid: workspaceId || id || "", pool, withIdp: true })
      .then(res => {
        createdPool = res.data;
        return res;
      })
      .then(res => {
        notifySuccess(
          <span>
            {mode === "b2b" ? (
              <>
                User population <strong>{data.name}</strong> created
              </>
            ) : (
              <>
                Identity pool <strong>{data.name}</strong> with Identity Provider{" "}
                <strong>{res?.data?.name ?? ""}</strong> created
              </>
            )}
          </span>
        );
      })
      .then(() =>
        queryClient.removeQueries({ queryKey: listWorkspacePoolsQueryKey(workspaceId || id || "") })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUserWorkspacePoolsQueryKey(workspaceId || id || ""),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listIDPsQueryKey(getTenantId(), workspaceId || id || ""),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .then(() => onCreated(createdPool))
      .then(onClose)
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <Dialog
      id="identity-pool-create-dialog"
      title={
        mode === "b2b" ? (
          <div className={classes.titleWithTooltip}>
            <span style={{ marginRight: 8 }}>Create user population</span>
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
              <IconButton
                onClick={() => setOpenTooltip(openTooltip => !openTooltip)}
                className={cx(openTooltip && classes.openTooltip)}
              >
                <IconTooltip
                  id={`${id}-tooltip`}
                  open={openTooltip}
                  noIconMargin
                  title={
                    <div>
                      <div>
                        What are <b>User populations</b>?
                      </div>
                      <p style={{ lineHeight: "18px" }}>
                        It's a powerful tool for organizations to logically separate identity and
                        access management process.
                      </p>
                      <div>
                        <b>
                          <Link
                            href="https://cloudentity.com/developers/platform/identities/user-populations/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn more
                          </Link>
                        </b>
                      </div>
                    </div>
                  }
                  placement="bottom"
                />
              </IconButton>
            </ClickAwayListener>
          </div>
        ) : (
          "Create identity pool"
        )
      }
      onClose={onClose}
    >
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label={mode === "b2b" ? "Name" : "Pool name"}
          placeholder={mode === "b2b" ? "e.g. suppliers, merchants, contractors" : ""}
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespacesDashUnderscore({
                label: "Name",
              }),
            },
          }}
        />

        <TextField name="description" label="Description" multiline maxRows={3} minRows={3} />

        <FormFooter
          onCancel={onClose}
          onSubmit={handleCreatePool}
          submitText="Create"
          submitWithEnterKey
        />
      </Form>
    </Dialog>
  );
}
