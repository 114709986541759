import React, { ReactNode } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";

export enum Steps {
  WELCOME = "welcome",
  MAIN_USE_CASE_SIGN_IN = "sign-in",
  MAIN_USE_CASE_REGISTER_USER = "register-user",
  MAIN_USE_CASE_EXPLORE = "explore",
}

export const useCommonStyles = makeStyles()(theme => ({
  leftWithPreview: {
    width: 650,
    boxShadow:
      "0px 0.941px 0.941px 0px rgba(0, 0, 0, 0.08) inset, 0px 0px 11.289px 0px rgba(0, 0, 0, 0.10)",
    zIndex: 1,
  },
  right: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    position: "sticky",
    top: TOP_BAR_HEIGHT,
  },
  header: {
    margin: "10px 0",
  },
  intro: {
    marginBottom: 24,
    position: "relative",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  additionalSection: {
    padding: "12px 0 32px",
  },
  buttonExplore: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#eef4fb",
    borderRadius: 4,
    padding: "0px 16px",
    minHeight: 32,
    "& > span": {
      fontWeight: 500,
    },
  },
  backButton: {
    color: theme.palette.secondary.light,
    marginLeft: -16,
  },
  smallInfo: {
    color: theme.custom.sa.neutrals.n80,
    fontSize: 12,
    marginTop: 12,
  },
  divider: {
    margin: "24px 0",
  },
  recommendedChip: {
    color: "#eab344",
    backgroundColor: theme.custom.sa.complimentary.warning.low,
    padding: "4px 8px",
    position: "absolute",
    top: 8,
    right: 8,
    borderRadius: 4,
  },
}));

export function SmallInfoText({ children, fontSize }: { children: ReactNode; fontSize?: number }) {
  const { classes: commonClasses } = useCommonStyles();

  return (
    <Typography
      variant="textMD"
      component="div"
      className={commonClasses.smallInfo}
      style={fontSize ? { fontSize } : {}}
    >
      {children}
    </Typography>
  );
}
