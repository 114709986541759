import React, { CSSProperties, ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import warningImg from "../../assets/images/icons/common/warning-icon.svg";

type ModeType = "info" | "warning";

const useStyles = makeStyles<{ mode: ModeType }>()((theme, { mode }) => ({
  dialogPaperRoot: {
    minWidth: 584,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
  button: {
    padding: "8px 24px",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    padding: "16px 32px",
    ...(mode === "warning"
      ? {
          backgroundColor: theme.palette.error.light,
          borderBottom: "1px solid #FFE2E1",
        }
      : {}),
  },
  dialogTitleImg: {
    marginRight: 12,
  },
  actionsContainer: {
    marginTop: 32,
    display: "flex",
    justifyContent: "flex-end",
  },
  warningItem: {
    marginTop: 4,
    display: "flex",
  },
  confirmButton: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none !important",
      ...(mode === "warning"
        ? {
            backgroundColor: "#AD241C",
          }
        : {}),
    },
  },
  cancelButton: {
    marginRight: 24,
  },
}));

interface Props extends Omit<DialogProps, "open" | "title" | "content"> {
  title?: string | ReactNode;
  content?: ReactNode;
  warningItems?: (string | { key: string; node: ReactNode })[] | undefined;
  postWarningsContent?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  extraActions?: ReactNode;
  progress?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onlyExtraActions?: boolean;
  withClosingButton?: boolean;
  mode?: ModeType;
  confirmButtonProps?: { disabled?: boolean };
  buttonsStyle?: CSSProperties;
}

const ConfirmationDialog = ({
  title,
  content,
  warningItems,
  postWarningsContent,
  confirmText,
  cancelText,
  extraActions,
  progress,
  onCancel,
  onConfirm,
  onlyExtraActions = false,
  withClosingButton,
  mode = "warning",
  confirmButtonProps,
  buttonsStyle = {},
  ...props
}: Props) => {
  const { classes } = useStyles({ mode });

  return (
    <Dialog
      open
      onClick={e => e.stopPropagation()} // to prevent event propagation on row click and dialog
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onCancel();
        }
      }}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.dialogPaperRoot,
      }}
      {...props}
    >
      {title && (
        <DialogTitle id="confirmation-dialog-title" className={classes.dialogTitle}>
          {mode === "warning" && (
            <img src={warningImg} alt="warning" className={classes.dialogTitleImg} />
          )}
          {title}
        </DialogTitle>
      )}
      <DialogContent style={{ paddingTop: 32 }}>
        {withClosingButton && (
          <IconButton
            id="close-button"
            aria-label="close"
            className={classes.closeButton}
            onClick={onCancel}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography id="confirmation-dialog-content" component="div" variant="body2">
          {content}
        </Typography>
        {warningItems && (
          <div id="confirmation-dialog-warning" style={{ marginTop: 16 }}>
            {warningItems.map(i => (
              <div className={classes.warningItem} key={typeof i === "string" ? i : i.key}>
                <DoneIcon style={{ marginRight: 10, color: "#BD271E" }} />{" "}
                <Typography style={{ fontSize: 14 }}>
                  {typeof i === "string" ? i : i.node}
                </Typography>
              </div>
            ))}
          </div>
        )}
        {postWarningsContent && (
          <Typography
            id="confirmation-dialog-post-warnings-content"
            component="div"
            variant="body1"
            style={{ marginTop: 16 }}
          >
            {postWarningsContent}
          </Typography>
        )}
        <div className={classes.actionsContainer} style={buttonsStyle}>
          {!onlyExtraActions && (
            <>
              <Button
                id="cancel-button"
                onClick={() => onCancel()}
                disabled={progress}
                className={classes.cancelButton}
                color="secondary"
              >
                {cancelText || "Cancel"}
              </Button>

              <LoadingButton
                id="confirm-button"
                onClick={() => onConfirm()}
                className={classes.confirmButton}
                variant="contained"
                loading={progress}
                color={mode === "warning" ? "error" : "primary"}
                {...(confirmButtonProps || {})}
              >
                {confirmText || "Confirm"}
              </LoadingButton>
            </>
          )}
          {extraActions}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
