import React from "react";

import Avatar from "@mui/material/Avatar";
import {
  Briefcase,
  Coffee,
  DollarSign,
  Link,
  Monitor,
  MousePointer,
  Terminal,
} from "react-feather";

import {
  ServerInfo,
  ServerInfoProfileEnum,
  ServerInfoTypeEnum,
  ServerResponse,
  ServerResponseProfileEnum,
  ServerResponseTypeEnum,
  WorkspaceResponse,
  WorkspaceResponseProfileEnum,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import brazilIcon from "../../../assets/images/icons/workspaceDirectory/brazil-large.svg";
import brazilSmallIcon from "../../../assets/images/icons/workspaceDirectory/brazil-small.svg";
import cdrIcon from "../../../assets/images/icons/workspaceDirectory/cdr-large.svg";
import cdrSmallIcon from "../../../assets/images/icons/workspaceDirectory/cdr-small.svg";
import connectIdIcon from "../../../assets/images/icons/workspaceDirectory/connect-id-large.svg";
import connectIdSmallIcon from "../../../assets/images/icons/workspaceDirectory/connect-id-small.svg";
import fdxIcon from "../../../assets/images/icons/workspaceDirectory/fdx-large.svg";
import fdxSmallIcon from "../../../assets/images/icons/workspaceDirectory/fdx-small.svg";
import ksaIcon from "../../../assets/images/icons/workspaceDirectory/ksa-large.svg";
import ksaSmallIcon from "../../../assets/images/icons/workspaceDirectory/ksa-small.svg";
import obGenericIcon from "../../../assets/images/icons/workspaceDirectory/ob-generic-large.svg";
import obGenericSmallIcon from "../../../assets/images/icons/workspaceDirectory/ob-generic-small.svg";
import playCircleIcon from "../../../assets/images/icons/workspaceDirectory/play-circle.svg";
import shieldBoltIcon from "../../../assets/images/icons/workspaceDirectory/shield-bolt.svg";
import ukIcon from "../../../assets/images/icons/workspaceDirectory/uk-large.svg";
import ukSmallIcon from "../../../assets/images/icons/workspaceDirectory/uk-small.svg";
import { capitalizeFirstLetter } from "./identityPools/schemas/schemas.utils";
import { workspaceAppTypes } from "./workspace-directory.common";

const iconSizes = {
  large: 32,
  normal: 24,
  small: 16,
  smaller: 12,
};

export type SizeType = "large" | "normal" | "small" | "smaller";

export const EMPTY_VALUE_PLACEHOLDER_SERVER = "empty-value-placeholder";

const getServerProfileNamesAndIcons = (
  profile:
    | ServerResponseProfileEnum
    | WorkspaceResponseProfileEnum
    | ServerInfoProfileEnum
    | undefined,
  size?: SizeType
) => {
  const pxSize = iconSizes[size ?? "normal"];

  switch (profile) {
    case ServerResponseProfileEnum.Default:
      return {
        name: "Default",
        color: "#6366F1",
        icon: <img alt="default" src={shieldBoltIcon} style={{ width: pxSize, height: pxSize }} />,
      };
    case ServerResponseProfileEnum.Demo:
      return {
        name: "Demo",
        color: "#06B6D4",
        icon: <img alt="default" src={playCircleIcon} style={{ width: pxSize, height: pxSize }} />,
      };
    case ServerResponseProfileEnum.Workforce:
      return {
        name: "Workforce",
        color: "#B81965",
        icon: <Briefcase size={pxSize} />,
      };
    case ServerResponseProfileEnum.Consumer:
      return {
        name: "Customer",
        color: "#EAB344",
        icon: <Coffee size={pxSize} />,
      };
    case ServerResponseProfileEnum.Partners:
      return {
        name: "Partner",
        color: "#FF6200",
        icon: <Link size={pxSize} />,
      };
    case ServerResponseProfileEnum.ThirdParty:
      return {
        name: "Third-Party",
        color: "#4288AE",
        icon: <Terminal size={pxSize} />,
      };
    case ServerResponseProfileEnum.FapiAdvanced:
      return {
        name: "FAPI Advanced",
        color: "#5DB082",
        icon: <DollarSign size={pxSize} />,
      };
    case ServerResponseProfileEnum.FapiRw:
      return {
        name: "FAPI Read/Write",
        color: "#5DB082",
        icon: <DollarSign size={pxSize} />,
      };
    case ServerResponseProfileEnum.FapiRo:
      return {
        name: "FAPI Readonly",
        color: "#5DB082",
        icon: <DollarSign size={pxSize} />,
      };
    case ServerResponseProfileEnum.OpenbankingUkFapiAdvanced:
      return {
        name: "Open Banking UK FAPI Advanced",
        color: "#0083FF",
        icon: <img alt="openbanking-uk" src={size === "small" ? ukSmallIcon : ukIcon} />,
      };
    case ServerResponseProfileEnum.OpenbankingUk:
      return {
        name: "Open Banking UK",
        color: "#0083FF",
        icon: <img alt="openbanking-uk" src={size === "small" ? ukSmallIcon : ukIcon} />,
      };
    case ServerResponseProfileEnum.OpenbankingBr:
      return {
        name: "Open Finance BR",
        color: "#85d948",
        icon: <img alt="openbanking-br" src={size === "small" ? brazilSmallIcon : brazilIcon} />,
      };
    case ServerResponseProfileEnum.CdrAustralia:
      return {
        name: "CDR Australia",
        color: "#fabc3e",
        icon: <img alt="openbanking-cdr" src={size === "small" ? cdrSmallIcon : cdrIcon} />,
      };
    case ServerResponseProfileEnum.CdrAustraliaFapiRw:
      return {
        name: "CDR Australia FAPI Read/Write",
        color: "#fabc3e",
        icon: <img alt="openbanking-cdr" src={size === "small" ? cdrSmallIcon : cdrIcon} />,
      };
    case ServerResponseProfileEnum.Fdx:
      return {
        name: "FDX",
        color: "#009687",
        icon: <img alt="openbanking-fdx" src={size === "small" ? fdxSmallIcon : fdxIcon} />,
      };
    case ServerResponseProfileEnum.OpenbankingKsa:
      return {
        name: "Open Banking KSA",
        color: "#20A81E",
        icon: <img alt="openbanking-ksa" src={size === "small" ? ksaSmallIcon : ksaIcon} />,
      };
    case ServerResponseProfileEnum.ConnectId:
      return {
        name: "Connect ID",
        color: "#bdedd2",
        icon: <img alt="connect-id" src={size === "small" ? connectIdSmallIcon : connectIdIcon} />,
      };
    case ServerResponseProfileEnum.Fapi20Security:
      return {
        name: "Open Banking Generic",
        color: "#20A81E",
        icon: (
          <img
            alt="openbanking-generic"
            src={size === "small" ? obGenericSmallIcon : obGenericIcon}
          />
        ),
      };
    default:
      return {
        name: "",
        color: "#6366F1",
        icon: <img alt="default" src={shieldBoltIcon} style={{ width: pxSize, height: pxSize }} />,
      };
  }
};

export const getServerTitle = ({
  id,
  type,
  profile,
}:
  | {
      id?: string;
      type?: ServerResponseTypeEnum | WorkspaceResponseTypeEnum | ServerInfoTypeEnum;
      profile?: ServerResponseProfileEnum | WorkspaceResponseProfileEnum | ServerInfoProfileEnum;
    }
  | undefined = {}) => {
  if (type === ServerResponseTypeEnum.Developer) {
    return "Developer Portal";
  }

  if (
    (type === ServerResponseTypeEnum.Regular && id === "demo") ||
    profile === ServerResponseProfileEnum.Demo
  ) {
    return "Demo Workspace";
  }

  if (type === ServerResponseTypeEnum.Regular && id === "default") {
    return "Default Workspace";
  }

  if (type === ServerResponseTypeEnum.Regular && profile === ServerResponseProfileEnum.Default) {
    return "Regular Workspace";
  }

  if (type === ServerResponseTypeEnum.Admin) {
    return "Admin Workspace";
  }

  if (type === ServerResponseTypeEnum.System) {
    return "System Workspace";
  }

  if (type === ServerResponseTypeEnum.Organization) {
    return "Organization";
  }

  return (
    workspaceAppTypes.find(wat => wat.profile === profile)?.title ||
    capitalizeFirstLetter(profile || "")
  );
};

export const getServerDefaultColorByProfile = (profile: ServerResponseProfileEnum | undefined) => {
  return (profile && getServerProfileNamesAndIcons(profile)?.color) || "#cacaca";
};

export const getServerAvatarIcon = (
  server?: Pick<
    ServerResponse | WorkspaceResponse | (ServerInfo & { id: string }),
    "id" | "name" | "type" | "profile" | "color"
  >,
  size?: SizeType,
  isOrganization?: boolean
) => {
  const pxSize = iconSizes[size ?? "normal"];

  if (server?.type === ServerResponseTypeEnum.Developer) {
    return <Monitor size={pxSize} />;
  }

  if (server?.type === ServerResponseTypeEnum.Regular && server?.id === "demo") {
    const p = getServerProfileNamesAndIcons(ServerResponseProfileEnum.Demo, size);
    return p?.icon;
  }

  if (server?.type === ServerResponseTypeEnum.Organization || isOrganization) {
    return server?.name?.slice(0, 2)?.toUpperCase() || "Org";
  }

  if (server?.id === EMPTY_VALUE_PLACEHOLDER_SERVER) {
    return <MousePointer size={14} />;
  }

  const p = getServerProfileNamesAndIcons(server?.profile, size);
  return p?.icon;
};

export const getServerAvatarSquare = (
  server?: Pick<ServerResponse, "id" | "name" | "type" | "profile" | "color">
) => {
  const p = getServerProfileNamesAndIcons(server?.profile);

  return (
    <Avatar
      variant="square"
      style={{ backgroundColor: server?.color || p?.color, width: "100%", height: "100%" }}
    >
      {getServerAvatarIcon(server)}
    </Avatar>
  );
};

export const profileToCreateDemoWorkspaceOption = [
  ServerResponseProfileEnum.OpenbankingUkFapiAdvanced,
  ServerResponseProfileEnum.OpenbankingUk,
  ServerResponseProfileEnum.OpenbankingBr,
  ServerResponseProfileEnum.CdrAustralia,
  ServerResponseProfileEnum.CdrAustraliaFapiRw,
  ServerResponseProfileEnum.Fdx,
  ServerResponseProfileEnum.Fapi20Security,
];

export function isOpenbanking(
  s: ServerResponseProfileEnum | WorkspaceResponseProfileEnum | undefined
) {
  return (
    s?.includes("openbanking") ||
    s?.includes("cdr") ||
    s?.includes("fapi") ||
    s?.includes("fdx") ||
    s?.includes("connect_id")
  );
}

export const isOPBWithDemoWorkspace = (profile: ServerResponseProfileEnum | undefined) =>
  profile && profileToCreateDemoWorkspaceOption.includes(profile);

export const isOPBrazil = (profile: ServerResponseProfileEnum | undefined) =>
  (profile && profile === ServerResponseProfileEnum.OpenbankingBr) || false;

export const isOPUK = (profile: ServerResponseProfileEnum | undefined) =>
  (profile &&
    (profile === ServerResponseProfileEnum.OpenbankingUk ||
      profile === ServerResponseProfileEnum.OpenbankingUkFapiAdvanced)) ||
  false;

export const isOPCDR = (profile: ServerResponseProfileEnum | undefined) =>
  (profile &&
    (profile === ServerResponseProfileEnum.CdrAustralia ||
      profile === ServerResponseProfileEnum.CdrAustraliaFapiRw)) ||
  false;

export const isOPFDX = (profile: ServerResponseProfileEnum | undefined) =>
  (profile && profile === ServerResponseProfileEnum.Fdx) || false;

export const isOPGeneric = (profile: ServerResponseProfileEnum | undefined) =>
  (profile && profile === ServerResponseProfileEnum.Fapi20Security) || false;

export const getHasOpenBankingQuickstartView = (
  profile: ServerResponseProfileEnum | undefined,
  id: string
) =>
  (profile &&
    isOPBWithDemoWorkspace(profile) &&
    !(profile === ServerResponseProfileEnum.Fapi20Security && id.startsWith("hyperscalebank-"))) ||
  false;
