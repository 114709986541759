import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { ChevronDown, ChevronUp } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import Button from "../../../../common/components/Button";
import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import Switch from "../../../../common/components/Switch";
import { useWorkspace } from "../../common/useWorkspace";
import { providers } from "../../identities/identities.utils";
import CreateIdp from "../../identities/identities_view_tabbed/identitiesListSimple/CreateIdp";
import {
  handleExistingIdpCheckboxChange,
  handlePoolMechanismChange,
} from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimple";
import IdentitiesListSimplePreview from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimplePreview";
import { poolAuthMechanismMapper } from "../../workspaceDirectory/identityPools/identityPoolCreate/IdentityPoolsAuthnSelect";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";
import ProgressIndicator from "./ProgressIndicator";

const useStyles = makeStyles<{
  noMargin?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
  disabled?: boolean;
}>()((theme, { noMargin, hasPrev, hasNext, disabled }) => ({
  container: {
    padding: 32,
  },
  right: {
    flex: 1,
  },
  switchCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: noMargin ? 0 : 8,
    padding: 16,
    position: "relative",
    ...(disabled ? { backgroundColor: theme.custom.sa.neutrals.n0 } : {}),
    ...(hasPrev ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}),
    ...(hasNext
      ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: "none" }
      : {}),
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    background: "none",
    border: "solid 1px black",
    color: theme.palette.secondary.light,
    width: 30,
    height: 30,
    marginRight: 8,
  },
  avatarSvg: {
    svg: {
      width: 20,
    },
  },
  idpAvatar: {
    border: "none",
    width: 28,
    height: 28,
    "& > img": {
      width: "100%",
    },
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
}));
interface Props {
  poolIdps: IDP[];
  externalIdps: IDP[];
  pool: PoolResponse | undefined;
  selectedAuthenticationMethods: (PoolResponseAuthenticationMechanismsEnum | string)[];
  setSelectedAuthenticationMethods: React.Dispatch<
    React.SetStateAction<(PoolResponseAuthenticationMechanismsEnum | string)[]>
  >;
  onChangeStep: (step: Steps) => void;
}

export default function SignInStep({ poolIdps, externalIdps, pool, onChangeStep }: Props) {
  const { cx, classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles({});
  const queryClient = useQueryClient();
  const [workspace] = useWorkspace();

  const [openAdditional, setOpenAdditional] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [createIdp, setCreateIdp] = useState("");

  const poolMethods = [
    PoolResponseAuthenticationMechanismsEnum.Webauthn,
    PoolResponseAuthenticationMechanismsEnum.Otp,
    PoolResponseAuthenticationMechanismsEnum.Password,
  ];

  const idps = ["google", "github", "azure"];
  const excludedIdps = [
    "cloudentity",
    "static",
    "custom",
    "external",
    "identity_pool",
    "organization",
    "google_embedded",
    "github_embedded",
    "saml",
  ];
  const upgradeIdps = providers
    .map(v => v.method)
    .filter(v => !idps.includes(v) && !excludedIdps.includes(v));
  const poolProvider = providers.find(provider => provider.method === "identity_pool");

  return (
    <>
      <div className={commonClasses.leftWithPreview}>
        <div className={classes.container}>
          <div className={commonClasses.intro}>
            <Typography variant="h2" className={commonClasses.header}>
              Select your customer sign-in options
            </Typography>
            <SmallInfoText fontSize={14}>
              You have the flexibility to customize it later, so there's no need to worry
            </SmallInfoText>
            <ProgressIndicator value={33} text="1" caption="Out of 3" />
            <Divider className={commonClasses.divider} />
          </div>

          {poolIdps.length === 0 && (
            <div>
              <Typography variant="h5">Authentication methods</Typography>
              <div style={{ margin: "16px 0" }}>
                <SmallInfoText>No SecureAuth provider</SmallInfoText>
              </div>
            </div>
          )}

          {poolIdps.length === 1 && (
            <div>
              <Typography variant="h5">Authentication methods</Typography>
              <div style={{ margin: "16px 0" }}>
                {poolMethods.map(method => {
                  const { id, label, icon } = poolAuthMechanismMapper[method];
                  const isChecked = !!pool?.authentication_mechanisms?.includes(method);
                  return (
                    <SwitchCard
                      key={id}
                      id={id}
                      name={label}
                      icon={icon}
                      noSvgStyle={method === PoolResponseAuthenticationMechanismsEnum.Webauthn}
                      checked={isChecked}
                      onChange={checked =>
                        handlePoolMechanismChange(queryClient, pool, id, method, checked, () => {})
                      }
                      disabled={
                        isChecked && method === PoolResponseAuthenticationMechanismsEnum.Webauthn
                      }
                    />
                  );
                })}
              </div>
            </div>
          )}

          {poolIdps.length > 1 && (
            <>
              <Typography variant="h5">SecureAuth providers</Typography>
              <div style={{ margin: "16px 0" }}>
                {poolIdps.map(idp => (
                  <SwitchCard
                    key={idp.id ?? ""}
                    id={idp.id ?? ""}
                    name={idp.name ?? ""}
                    icon={<img src={poolProvider?.icon} alt={idp.id} />}
                    checked={!idp.disabled}
                    onChange={checked =>
                      handleExistingIdpCheckboxChange(
                        queryClient,
                        workspace,
                        idp.id ?? "",
                        idp,
                        checked,
                        () => {}
                      )
                    }
                  />
                ))}
              </div>
            </>
          )}

          <div className={commonClasses.additionalSection}>
            <div className={commonClasses.flexContainer}>
              <Typography variant="h5">All Connections</Typography>
              <Button
                variant="text"
                className={commonClasses.buttonExplore}
                onClick={() => setOpenAdditional(isOpen => !isOpen)}
              >
                <Typography variant="textSM">Explore connections</Typography>
                {openAdditional ? (
                  <ChevronUp size={16} style={{ marginLeft: 4 }} />
                ) : (
                  <ChevronDown size={16} style={{ marginLeft: 4 }} />
                )}
              </Button>
            </div>
            <SmallInfoText>
              Configure social connections like Google or Upgrade to enterprise connections so that
              you can let your users sign in with them.
            </SmallInfoText>
            {openAdditional && (
              <div>
                <Typography variant="h6" style={{ margin: "16px 0" }}>
                  Social Connections
                </Typography>

                {idps.map((idp, i) => {
                  const provider = providers.find(provider => provider.method === idp);
                  const found = externalIdps.find(v => v.method === idp);
                  return provider ? (
                    <SwitchCard
                      key={idp}
                      id={idp}
                      name={provider.name}
                      icon={<img src={provider.icon} alt={idp} />}
                      idpAvatar
                      checked={!!found && !found.disabled}
                      onChange={checked => {
                        if (checked && !found) {
                          setCreateIdp(idp);
                        }

                        handleExistingIdpCheckboxChange(
                          queryClient,
                          workspace,
                          found?.id ?? "",
                          found,
                          checked,
                          () => {}
                        );
                      }}
                      noMargin
                      hasPrev={i > 0}
                      hasNext={i < poolMethods.length - 1}
                    />
                  ) : null;
                })}

                <Typography
                  variant="h6"
                  className={commonClasses.flexContainer}
                  style={{ margin: "16px 0" }}
                >
                  Enterprise Connections
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => setOpenUpgradeDialog(true)}
                  >
                    Learn more
                  </Button>
                </Typography>
                <SmallInfoText>
                  Upgrade to the Enterprise plan to add more connections and unlock an entirely new
                  level of features.
                </SmallInfoText>
              </div>
            )}
          </div>

          <div className={commonClasses.flexContainer}>
            <Button
              variant="text"
              onClick={() => onChangeStep(Steps.WELCOME)}
              color="inherit"
              className={commonClasses.backButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => onChangeStep(Steps.MAIN_USE_CASE_REGISTER_USER)}
            >
              Continue
            </Button>
          </div>

          {openUpgradeDialog && (
            <ConfirmationDialog
              id="idp-upgrade-dialog"
              title="Upgrade to SecureAuth CIAM Enterprise Suite"
              onCancel={() => setOpenUpgradeDialog(false)}
              onConfirm={() => {}}
              confirmText="Upgrade"
              mode="info"
              content={
                <>
                  <Typography variant="h6">Enterprise Connections</Typography>
                  <SmallInfoText>
                    Upgrade to the Enterprise plan to add more connections and unlock an entirely
                    new level of features.
                  </SmallInfoText>
                  <div style={{ marginTop: 16 }}>
                    {upgradeIdps.map((idp, i) => {
                      const provider = providers.find(provider => provider.method === idp);
                      return provider ? (
                        <SwitchCard
                          key={idp}
                          id={idp}
                          name={provider.name}
                          icon={<img src={provider.icon} alt={idp} />}
                          idpAvatar
                          checked={false}
                          onChange={_ => {}}
                          disabled
                          noMargin
                          hasPrev={i > 0}
                          hasNext={i < upgradeIdps.length - 1}
                        />
                      ) : null;
                    })}
                  </div>
                </>
              }
            />
          )}

          {createIdp && (
            <CreateIdp
              method={createIdp}
              workspace={workspace}
              handleClose={() => setCreateIdp("")}
              onCreated={() => {}}
            />
          )}
        </div>
      </div>
      <div className={cx(commonClasses.right, classes.right)}>
        <IdentitiesListSimplePreview
          workspace={workspace}
          poolIdps={poolIdps}
          externalIdps={externalIdps}
          pool={pool}
        />
      </div>
    </>
  );
}

function SwitchCard({
  name,
  icon,
  id,
  noSvgStyle,
  idpAvatar,
  checked,
  onChange,
  disabled,
  noMargin,
  hasPrev,
  hasNext,
}: {
  name: string;
  icon: any;
  id: string;
  noSvgStyle?: boolean;
  idpAvatar?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  noMargin?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
}) {
  const { cx, classes } = useStyles({ noMargin, hasPrev, hasNext, disabled });

  return (
    <Paper id={`switch-card-${id}`} className={classes.switchCard}>
      <div className={classes.flexContainer}>
        <Avatar
          className={cx(
            classes.avatar,
            !noSvgStyle && classes.avatarSvg,
            idpAvatar && classes.idpAvatar
          )}
        >
          {icon}
        </Avatar>
        <Typography variant="textMD">{name}</Typography>
      </div>

      <div className={classes.flexContainer}>
        <Switch
          checked={checked}
          onChange={(_, checked) => onChange(checked)}
          disabled={disabled}
        />
      </div>
    </Paper>
  );
}
