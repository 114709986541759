import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { makeStyles } from "tss-react/mui";

import { PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { useGetClients } from "../../services/adminClientsQuery";
import { useListIDPs } from "../../services/adminIDPsQuery";
import { useGetPool } from "../../services/adminIdentityPoolsQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useGetTenant } from "../../services/adminTenantsQuery";
import { useGetUserInfo } from "../../services/oauth2Query";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import { useWorkspace } from "../common/useWorkspace";
import { TenantMetadataRegistration } from "../welcome/workspaces/SetupBranding";
import Explore from "./mainUseCaseSteps/Explore";
import RegisterUser from "./mainUseCaseSteps/RegisterUser";
import SignInStep from "./mainUseCaseSteps/SignInStep";
import WelcomeStep from "./mainUseCaseSteps/WelcomeStep";
import { Steps } from "./utils";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    backgroundColor: "white",
    width: "100%",
  },
}));

export default function GetStarted() {
  const { classes } = useStyles();
  const [workspace] = useWorkspace();
  const navigate = useNavigate();
  const { step } = useParams<{ step: Steps }>();
  const tenantId = getTenantId();

  const userinfoQuery = useGetUserInfo();
  const tenantQuery = useGetTenant(tenantId);
  const listClientsQuery = useGetClients(tenantId, workspace);
  const getServerQuery = useGetAuthorizationServer(tenantId, workspace);
  const clients = listClientsQuery.data?.clients ?? [];

  const demoClient = clients.find(client => client.client_id === `${workspace}-demo`);
  const userPortal = clients.find(client => client.client_id === `${workspace}`);
  const demoLink =
    (demoClient &&
      (`${getServerQuery.data?.issuer_url ?? ""}/demo` || demoClient?.redirect_uris?.[0])) ||
    null;
  const userPortalLink = (userPortal && `${getServerQuery.data?.issuer_url ?? ""}/app`) || null;

  const listIDPsQuery = useListIDPs(tenantId, workspace);
  const idps = listIDPsQuery.data?.idps || [];

  const poolIdps = idps.filter(idp => idp.method === "identity_pool");
  const getPoolQuery = useGetPool(poolIdps[0]?.identity_pool_id ?? "", {
    enabled: poolIdps.length > 0,
  });
  const pool = getPoolQuery.data;

  const internalIdpsTypes = ["identity_pool", "static", "organization", "cloudentity", "external"];
  const externalIdps = idps.filter(idp => !internalIdpsTypes.includes(idp.method ?? ""));

  const [selectedAuthenticationMethods, setSelectedAuthenticationMethods] = useState<
    (PoolResponseAuthenticationMechanismsEnum | string)[]
  >([PoolResponseAuthenticationMechanismsEnum.Webauthn]);

  const onChangeStep = useCallback(
    (step: Steps) => {
      navigate(`/${workspace}/get-started-v2/${step}`);
    },
    [navigate, workspace]
  );

  const firstName =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.first_name ||
    userinfoQuery.data?.given_name ||
    "";

  const email =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.email ||
    userinfoQuery.data?.email ||
    "";

  useEffect(() => {
    if (!step || !Object.values(Steps).includes(step)) {
      onChangeStep(Steps.WELCOME);
    }
  }, [onChangeStep, step]);

  return (
    <PageContainer progress={tenantQuery.isLoading}>
      <PageContent noPadding style={{ padding: 0 }}>
        <div className={classes.container}>
          {step === Steps.WELCOME && (
            <WelcomeStep firstName={firstName} onChangeStep={onChangeStep} />
          )}
          {step === Steps.MAIN_USE_CASE_SIGN_IN && (
            <SignInStep
              poolIdps={poolIdps}
              externalIdps={externalIdps}
              pool={pool}
              selectedAuthenticationMethods={selectedAuthenticationMethods}
              setSelectedAuthenticationMethods={setSelectedAuthenticationMethods}
              onChangeStep={onChangeStep}
            />
          )}
          {step === Steps.MAIN_USE_CASE_REGISTER_USER && (
            <RegisterUser onChangeStep={onChangeStep} pool={pool} email={email} />
          )}
          {step === Steps.MAIN_USE_CASE_EXPLORE && (
            <Explore
              poolIdps={poolIdps}
              externalIdps={externalIdps}
              pool={pool}
              onChangeStep={onChangeStep}
              links={{ demo: demoLink, user: userPortalLink }}
            />
          )}
        </div>
      </PageContent>
    </PageContainer>
  );
}
