import React from "react";

import PageContainer from "../../admin/components/common/PageContainer";
import PageContent from "../../admin/components/common/PageContent";
import PageHeader from "../../admin/components/common/PageHeader";
import { useOrganization } from "../../admin/components/common/useOrganization";
import IdentitiesListSimple from "../../admin/components/identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimple";
import { useListIDPs } from "../../admin/services/adminIDPsQuery";
import { getTenantId } from "../../common/api/paths";

export default function B2BSignIn() {
  const [organizationId] = useOrganization();
  const listIDPsQuery = useListIDPs(getTenantId(), organizationId);

  return (
    <PageContainer>
      <PageHeader style={{ marginTop: 64 }} title="Sign-in and SSO" maxWidth={false} />
      <PageContent>
        <IdentitiesListSimple
          identities={listIDPsQuery.data?.idps ?? []}
          mode="simple"
          workspace={organizationId}
          setMode={undefined}
          tenantSignInAndSSO
          managePoolsInDialog
          hideExtensions
        />
      </PageContent>
    </PageContainer>
  );
}
