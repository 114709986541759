import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { AccessorFnColumnDef } from "@tanstack/react-table";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import {
  IdentityPoolRoleSubject,
  ServerResponseTypeEnum,
  TenantRoleSubject,
  WorkspaceRoleSubject,
} from "@cloudentity/acp-admin";
import { ExtendedUser, PoolResponse, SupportedJSONSchema } from "@cloudentity/acp-identity";

import identityUsersApi from "../../../../../../../admin/services/adminIdentityUsersApi";
import {
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import AdvancedTable from "../../../../../../../common/components/table/AdvancedTable";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import { setInLocalStorage } from "../../../../../../../common/utils/localStorage.utils";
import { useCheckPoolPermissions } from "../../../../../../services/adminIdentityPoolsQuery";
import { useListUsersWithPagination } from "../../../../../../services/adminIdentityUsersQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../../../../../../services/adminPermissionsQuery";
import {
  useListIdentityPoolRoles,
  useListWorkspaceRoles,
} from "../../../../../../services/adminRolesQuery";
import { useGetWorkspace } from "../../../../../../services/adminServersQuery";
import PageContent from "../../../../../common/PageContent";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { useWorkspaceDefaultClientApp } from "../../../../../common/useWorkspaceDefaultClientApp";
import { useTenantRoles } from "../../../../administrator/useTenantRoles";
import { fieldToTitle, getAllPaths, getRequiredPaths } from "../../../schemas/schemas.utils";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolUserAddressesCell from "./IdentityPoolUserAddressesCell";
import IdentityPoolUserIdentifierCell from "./IdentityPoolUserIdentifierCell";
import IdentityPoolUserRowMenu from "./IdentityPoolUserRowMenu";
import IdentityPoolUsersAddImportUsers from "./IdentityPoolUsersAddImportUsers";
import IdentityPoolUsersEmptyState from "./IdentityPoolUsersEmptyState";
import StatusChip from "./StatusChip";
import UsersFilter, { escapePercentageSignInValue } from "./UsersFilter";
import {
  getColumnsFromStorage,
  getColumnsStorageKey,
  getFormattedDate,
  getUserRole,
  getUserRolesDisplayNames,
  tenantRoleToDisplayRole,
} from "./utils";

function getColumns(
  isRoleColumnVisible: boolean,
  poolMode: string,
  isOrganizationWorkspace: boolean,
  isAdminWorkspace: boolean,
  tenantSubjects: TenantRoleSubject[] = [],
  workspaceSubjects: WorkspaceRoleSubject[] = [],
  identityPoolSubjects: IdentityPoolRoleSubject[] = []
): AccessorFnColumnDef<ExtendedUser, any>[] {
  return [
    {
      id: "identifiers",
      accessorFn: row => row.identifiers,
      cell: cell => <IdentityPoolUserIdentifierCell user={cell.row.original} />,
      header: "Identifiers",
      enableSorting: true,
    },
    {
      id: "addresses",
      accessorFn: row => row.addresses,
      cell: cell => <IdentityPoolUserAddressesCell user={cell.row.original} />,
      header: "Addresses",
    },
    ...(isRoleColumnVisible
      ? [
          {
            id: "role",
            accessorFn: row => getUserRole(row, tenantSubjects),
            cell: cell => {
              const role = getUserRole(cell.row.original, tenantSubjects);
              return <span>{tenantRoleToDisplayRole(role)}</span>;
            },
            header: "Tenant Role",
          },
        ]
      : []),
    ...(poolMode === "workspace" && (isOrganizationWorkspace || isAdminWorkspace)
      ? [
          {
            id: "roles",
            header: isOrganizationWorkspace ? "Roles" : "Workspace Roles",
            accessorFn: row => getUserRole(row, tenantSubjects),
            cell: cell => {
              return getUserRolesDisplayNames(
                cell.row.original,
                [],
                workspaceSubjects,
                identityPoolSubjects,
                isOrganizationWorkspace
              );
            },
          },
        ]
      : []),
    {
      id: "status",
      accessorFn: row => row.status,
      header: "Status",
      cell: cell => {
        const status = cell.row.original.status;
        return <StatusChip status={status} />;
      },
      enableSorting: true,
    },
    {
      id: "status_updated_at",
      accessorFn: row => row.status_updated_at,
      header: "Last Updated",
      cell: cell => {
        const status_updated_at = cell.row.original.status_updated_at;
        return getFormattedDate(status_updated_at ?? "");
      },
      enableSorting: true,
    },
  ];
}

const valueToString = (path, data) => {
  const v = get(data, path.split("."), "");
  if (Array.isArray(v)) {
    return JSON.stringify(v, null, 2);
  }
  if (typeof v === "object" && v !== null) {
    return JSON.stringify(v, null, 2);
  }
  return v;
};

const getPayloadMetadataColumns = (
  payloadSchema: SupportedJSONSchema | undefined,
  metadataSchema: SupportedJSONSchema | undefined,
  businessMetadataSchema: SupportedJSONSchema | undefined,
  pathsGetter: (schema: SupportedJSONSchema) => any[]
) => [
  ...pathsGetter(payloadSchema || {}).map(f => ({
    id: `payload.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.payload) ?? null,
  })),
  ...pathsGetter(metadataSchema || {}).map(f => ({
    id: `metadata.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.metadata) ?? null,
  })),
  ...pathsGetter(businessMetadataSchema || {}).map(f => ({
    id: `business_metadata.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.business_metadata) ?? null,
  })),
];

interface Props {
  pool: PoolResponse;
  payloadSchema: SupportedJSONSchema | undefined;
  metadataSchema: SupportedJSONSchema | undefined;
  businessMetadataSchema: SupportedJSONSchema | undefined;
  isWorkspaceOrPoolsRolesVisible: boolean;
}

export default function IdentityPoolUsers({
  pool,
  payloadSchema,
  metadataSchema,
  businessMetadataSchema,
  isWorkspaceOrPoolsRolesVisible,
}: Props) {
  const { rootUrl, mode: poolMode } = usePoolRootUrl();
  const [workspace] = useWorkspace();

  const [moreMenu, setMoreMenu] = useState<{ anchorEl: HTMLElement; user: ExtendedUser }>();
  const [sendActivationMsgDialog, setSendActivationMsgDialog] = useState<{ userId: string }>();

  const navigate = useNavigate();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkPoolPermissionsQuery = useCheckPoolPermissions(pool?.id!);
  const hasManageUserPermissions = !!checkPoolPermissionsQuery.data?.manage_identity_pool_users;
  const hasReadUserPermissions = !!checkPoolPermissionsQuery.data?.read_identity_pool_users;
  const canListSchemas = !!checkTenantPermissionsQuery.data?.list_identity_pools;

  const getWorkspaceQuery = useGetWorkspace(workspace, {
    enabled: poolMode === "workspace",
  });
  const isOrganizationWorkspace =
    getWorkspaceQuery.data?.type === ServerResponseTypeEnum.Organization;

  const defaultWorkspaceClientApp = useWorkspaceDefaultClientApp(workspace);

  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const { query: tenantRolesQuery, isEnabled: isRoleColumnVisible } = useTenantRoles({
    identityPoolId: pool?.id!,
    disabled: !isAdminWorkspaceAccessEnabled,
  });

  const isRolesEnabled = useFeature("roles");
  const isWithRolesEnabled = useFeature("with_roles");
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace, {
    enabled: !!workspace,
  });
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace!, {
    enabled:
      !!checkWorkspacePermissionsQuery.data?.read_roles &&
      !!workspace &&
      isRolesEnabled &&
      isWithRolesEnabled,
  });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(pool?.id!, {
    enabled:
      !!checkWorkspacePermissionsQuery.data?.read_roles &&
      !!pool?.id &&
      isRolesEnabled &&
      isWithRolesEnabled,
  });

  const allPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(payloadSchema, metadataSchema, businessMetadataSchema, getAllPaths),
    [payloadSchema, metadataSchema, businessMetadataSchema]
  );

  const requiredPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(
        payloadSchema,
        metadataSchema,
        businessMetadataSchema,
        getRequiredPaths
      ),
    [payloadSchema, metadataSchema, businessMetadataSchema]
  );

  const columns = [
    ...allPayloadMetadataColumns,
    ...getColumns(
      isRoleColumnVisible,
      poolMode,
      isOrganizationWorkspace,
      workspace === "admin",
      tenantRolesQuery.data?.subjects || [],
      listWorkspaceRolesQuery.data?.subjects || [],
      listIdentityPoolRolesQuery.data?.subjects || []
    ),
  ];

  const initialActiveColumns: string[] = useMemo(
    () => [
      ...(requiredPayloadMetadataColumns?.slice(0, 2).map(v => v.id) ?? []),
      "identifiers",
      "addresses",
      "role",
      ...(isWorkspaceOrPoolsRolesVisible ? ["roles"] : []),
      "status",
      "status_updated_at",
    ],
    [requiredPayloadMetadataColumns, isWorkspaceOrPoolsRolesVisible]
  );

  const [activeColumns, setActiveColumns] = useState<string[]>(
    getColumnsFromStorage(
      pool?.id ?? "",
      (columns || []).map(c => c.id ?? "")
    ) || initialActiveColumns
  );

  const handleSendActivationMessage = (userId: string, serverId?: string) => {
    identityUsersApi
      .sendActivationMessage({
        ipID: pool?.id ?? "",
        userID: userId,
        serverId: poolMode === "workspace" ? workspace : serverId,
        postActivationUrl: poolMode === "workspace" ? defaultWorkspaceClientApp.url : undefined,
      })
      .then(() => notifySuccess("Invitation message sent"))
      .catch(err => {
        if (
          err.response?.data?.status_code === 400 &&
          err.response?.data?.error === "no address exists"
        ) {
          notifyError("User don't have any address defined - add verifiable address for this user");
          return err;
        }
        if (
          err.response?.data?.status_code === 400 &&
          err.response?.data?.error === "more then one address exists"
        ) {
          setSendActivationMsgDialog({ userId });
          return;
        }
        return notifyErrorOrDefaultTo("Error occurred while trying to send invitation message");
      });
  };

  const listUsersWithPagination = useListUsersWithPagination(pool?.id!);
  const { onSetQuery, params } = listUsersWithPagination;

  const handleFilter = useCallback(
    filtering => {
      onSetQuery(
        isEmpty(filtering)
          ? undefined
          : decodeURIComponent(escapePercentageSignInValue(JSON.stringify(filtering)))
      );
    },
    [onSetQuery]
  );

  const isEmptyView =
    !listUsersWithPagination.isLoading &&
    listUsersWithPagination.isSuccess &&
    listUsersWithPagination.totalData?.length === 0 &&
    isEmpty(params.query);

  return (
    <PageContent
      progress={
        listUsersWithPagination.isLoading ||
        getWorkspaceQuery.isLoading ||
        checkWorkspacePermissionsQuery.isLoading ||
        checkTenantPermissionsQuery.isLoading ||
        checkPoolPermissionsQuery.isLoading ||
        tenantRolesQuery.isLoading ||
        defaultWorkspaceClientApp.isLoading
      }
    >
      {isEmptyView ? (
        <IdentityPoolUsersEmptyState
          workspaceId={workspace}
          pool={pool}
          hasManageUserPermissions={hasManageUserPermissions}
          isOrganizationWorkspace={isOrganizationWorkspace}
          onResetUsers={listUsersWithPagination.onReset}
          handleSendActivationMessage={handleSendActivationMessage}
        />
      ) : (
        <>
          {(canListSchemas || hasManageUserPermissions) && (
            <div style={{ display: "flex", alignItems: "flex-start", margin: "0 0 32px 0" }}>
              {canListSchemas && (
                <UsersFilter
                  onFilter={handleFilter}
                  style={{ flex: 1, marginRight: 24 }}
                  identityPoolId={pool?.id ?? ""}
                />
              )}

              {hasManageUserPermissions && (
                <IdentityPoolUsersAddImportUsers
                  workspaceId={workspace}
                  pool={pool}
                  isOrganizationWorkspace={isOrganizationWorkspace}
                  onResetUsers={listUsersWithPagination.onReset}
                  handleSendActivationMessage={handleSendActivationMessage}
                />
              )}
            </div>
          )}

          <AdvancedTable<ExtendedUser>
            tableId="identity-pool-users"
            initialActiveColumns={initialActiveColumns}
            columns={columns}
            activeColumns={activeColumns}
            setActiveColumns={columns => {
              setActiveColumns(columns);
              setInLocalStorage(getColumnsStorageKey(pool?.id ?? ""), JSON.stringify(columns));
            }}
            onRowClick={
              hasReadUserPermissions
                ? (e, rowId) => {
                    e.preventDefault();
                    navigate(`${rootUrl}/${pool?.id ?? ""}/users/${rowId}`);
                  }
                : undefined
            }
            onMoreIconClick={
              hasManageUserPermissions
                ? (e, user) => {
                    setMoreMenu({ anchorEl: e.currentTarget, user });
                  }
                : undefined
            }
            data={listUsersWithPagination}
          />
        </>
      )}

      {moreMenu && (
        <IdentityPoolUserRowMenu
          anchorEl={moreMenu.anchorEl}
          onClose={() => setMoreMenu(undefined)}
          user={moreMenu.user}
          pool={pool}
          handleSendActivationMessage={handleSendActivationMessage}
          sendActivationMsgDialog={sendActivationMsgDialog}
          setSendActivationMsgDialog={setSendActivationMsgDialog}
        />
      )}
    </PageContent>
  );
}
