import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Chip from "@mui/material/Chip";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Key, LogOut } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { useGetUserInfo } from "../../admin/services/oauth2Query";
import { getTenantId } from "../../common/api/paths";
import { cloudentityAuth, logout } from "../../common/auth/actions/actions";
import { getFromToken } from "../../common/auth/reducers/auth";
import { useSilentAuthentication } from "../../common/auth/useSilentAuthentication";
import Logo from "../../common/components/Logo";
import Progress from "../../common/components/Progress";
import RouterLink from "../../common/components/RouterLink";
import NavbarButton from "../../common/components/nav/NavbarButton";
import ProfileSettings from "../../common/components/nav/ProfileSettings";
import { useFeature } from "../services/userEnvironmentQuery";
import ChangePassword from "./ChangePassword";
import Dashboard from "./Dashboard";
import DashboardV2 from "./DashboardV2";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    height: "100%",
    "& *": {
      fontFamily: `"CustomTenantFont", ${theme.typography.fontFamily} !important`,
    },
  },
  logo: {
    verticalAlign: "middle",
    height: 32,
    width: "auto",
    maxWidth: 350,
    objectFit: "contain",
    marginLeft: 20,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    backgroundColor: theme.custom.headerBackground,
    height: 64,
  },
  content: {
    flexGrow: 1,
    paddingTop: 66,
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  toolBar: {
    padding: "0 !important",
    "& > first-of-child": {
      height: "100%",
    },
  },
  chip: {
    marginLeft: 16,
    color: "white",
  },
  menuPaper: {
    borderRadius: 4,
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1), 0px 3px 25px rgba(0, 0, 0, 0.1)",
    width: 258,
  },
  menuList: {
    padding: 0,
  },
  divider: {
    borderColor: "#F2F4FF",
  },
}));

export default function AuthenticatedUserAppBase({ appName, preview = false, logo, isLoading }) {
  const { classes } = useStyles();
  const [anchorProfile, setAnchorProfile] = useState<HTMLButtonElement | null>(null);

  useSilentAuthentication();
  const userinfoQuery = useGetUserInfo();
  const isUserFromPool = getFromToken("idpm", cloudentityAuth.getIDToken()) === "identity_pool";

  const isSelfServiceEnabled = useFeature("self_service");

  return isLoading ? (
    <Progress />
  ) : (
    <div className={classes.root} style={preview ? { pointerEvents: "none" } : {}}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar} style={preview ? { zIndex: 100 } : {}}>
        <Toolbar className={classes.toolBar}>
          <Logo
            classes={{ logo: classes.logo }}
            logoUrl={logo?.url}
            style={logo?.size ? { height: logo.size } : {}}
          />
          <Chip label={appName} className={classes.chip} variant="outlined" />
          <div style={{ flex: 1 }}></div>

          <NavbarButton
            onClick={e => setAnchorProfile(e.currentTarget)}
            email={userinfoQuery.data?.email}
            tenantId={getTenantId()}
          />

          <Menu
            id="topbar-profile-menu"
            open={Boolean(anchorProfile)}
            anchorEl={anchorProfile}
            onClose={() => setAnchorProfile(null)}
            classes={{ paper: classes.menuPaper, list: classes.menuList }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <ProfileSettings />
            <Divider className={classes.divider} />

            {isUserFromPool && !isSelfServiceEnabled && (
              <RouterLink to="/change-password" style={{ textDecoration: "none" }}>
                <ListItemButton id="change-password-button">
                  <ListItemIcon>
                    <Key style={{ marginRight: 12 }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: "nowrap", color: "rgba(0, 0, 0, 0.87)" }}
                    disableTypography
                    primary={<Typography variant="body2">Change password</Typography>}
                  />
                </ListItemButton>
              </RouterLink>
            )}

            <ListItemButton
              id="logout-button"
              onClick={() => logout("user", { withPromptLogin: true })}
            >
              <ListItemIcon>
                <LogOut />
              </ListItemIcon>
              <ListItemText
                style={{ whiteSpace: "nowrap" }}
                disableTypography
                primary={<Typography variant="body2">Logout</Typography>}
              />
            </ListItemButton>
          </Menu>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div data-version={isSelfServiceEnabled ? "2" : "1"} style={{ display: "none" }} />
        <Routes>
          <Route path="/" element={isSelfServiceEnabled ? <DashboardV2 /> : <Dashboard />} />
          {preview && <Route path="/brand-appearance" element={<Dashboard preview={preview} />} />}
          {isUserFromPool && <Route path="/change-password" element={<ChangePassword />} />}
        </Routes>
      </main>
    </div>
  );
}
