import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { QueryClient, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import bigDecimal from "js-big-decimal";

import {
  B2BUsers,
  B2BUsersApiListUsersB2BRequest,
  BaseExtendedUser,
  BaseExtendedUserStatusEnum,
  BaseUserWithData,
  BaseUserWithDataStatusEnum,
  ExtendedUser,
  ExtendedUserStatusEnum,
  PoolPermissionsResponse,
  UserMetadata,
  UserMetadataWithSchema,
  Users,
  UsersApiListUsersRequest,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../common/api/paths";
import { AdvancedTableData } from "../../common/components/table/types";
import { GlobalStoreContext } from "../GlobalStore/GlobalStore";
import b2bUsersApi from "./adminB2BUsersApi";
import adminB2BUsersApi from "./adminB2BUsersApi";
import { useCheckPoolPermissions } from "./adminIdentityPoolsQuery";
import identityUsersApi from "./adminIdentityUsersApi";
import adminIdentityUsersApi from "./adminIdentityUsersApi";
import { useQueryWithPoolPermissionCheck, withQueryError } from "./queryUtils";

const GET_USER_QUERY = "GET_USER_QUERY";

const LIST_USERS_QUERY = "LIST_USERS_QUERY";

export const getUserQueryKey = (tid, userId) => [GET_USER_QUERY, tid, userId];
export const getB2BUserQueryKey = (tid, userId) => [...getUserQueryKey(tid, userId), "b2b"];

const getUserMetadataQueryKey = (tid, userId, metadata) => [
  ...getUserQueryKey(tid, userId),
  "metadata",
  metadata,
];

export const listUsersQueryKey = (tid, poolId) => [LIST_USERS_QUERY, tid, poolId];
export const listB2BUsersQueryKey = (tid, poolId) => [...listUsersQueryKey(tid, poolId), "b2b"];

export const useGetUserMetadata = (
  poolId: string,
  userId: string,
  metadataType: "admin" | "business",
  options?
) =>
  useQueryWithPoolPermissionCheck<UserMetadataWithSchema>(
    poolId,
    "b2b_read_admin_metadata",
    getUserMetadataQueryKey(getTenantId(), userId, metadataType),
    withQueryError(async () => {
      const data = await identityUsersApi.getUserMetadata({
        ipID: poolId,
        userID: userId,
        metadataType,
      });
      return data.data;
    }, "Error occurred while trying to get identity user"),
    { staleTime: 5000, cacheTime: 5000, ...options }
  );

export const useGetUser = (poolId, userId, options?) =>
  useQuery<UserWithData>({
    queryKey: getUserQueryKey(getTenantId(), userId),
    queryFn: withQueryError<UserWithData>(async () => {
      const data = await identityUsersApi.getUser({ ipID: poolId, userID: userId });
      return data.data;
    }, "Error occurred while trying to get identity user"),
    ...{ staleTime: 5000, cacheTime: 5000, ...options },
  });

export const useGetB2BUser = (poolId, userId, options?) =>
  useQuery<BaseUserWithData>({
    queryKey: getB2BUserQueryKey(getTenantId(), userId),
    queryFn: withQueryError<BaseUserWithData>(async () => {
      const data = await adminB2BUsersApi.getB2BUser({ ipID: poolId, userID: userId });
      return data.data;
    }, "Error occurred while trying to get identity user"),
    ...{ staleTime: 5000, cacheTime: 5000, ...options },
  });

export const useListB2BUsersWithPagination = (
  identityPoolID: string
): AdvancedTableData<BaseExtendedUser> => {
  const usersWithPagination = useGenericListUsersWithPagination<BaseExtendedUser>(
    identityPoolID,
    useListB2BUsers<BaseExtendedUser>
  );

  const queryClient = useQueryClient();
  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);

  useQueries({
    queries: (usersWithPagination.data || []).map(user =>
      queryForMetadataFn(user, "admin", !!checkPoolPermissionsQuery.data?.b2b_read_admin_metadata)
    ),
  });

  useQueries({
    queries: (usersWithPagination.totalData || []).map(user =>
      queryForMetadataFn(user, "admin", !!checkPoolPermissionsQuery.data?.b2b_read_admin_metadata)
    ),
  });

  useQueries({
    queries: (usersWithPagination.data || []).map(user =>
      queryForMetadataFn(
        user,
        "business",
        !!checkPoolPermissionsQuery.data?.b2b_read_business_metadata
      )
    ),
  });

  useQueries({
    queries: (usersWithPagination.totalData || []).map(user =>
      queryForMetadataFn(
        user,
        "business",
        !!checkPoolPermissionsQuery.data?.b2b_read_business_metadata
      )
    ),
  });

  return {
    ...usersWithPagination,
    totalData: (usersWithPagination.totalData || []).map(user =>
      mapUserMetadataAndBusinessMetadataFn(user, queryClient, checkPoolPermissionsQuery.data)
    ),
    data: (usersWithPagination.data || []).map(user =>
      mapUserMetadataAndBusinessMetadataFn(user, queryClient, checkPoolPermissionsQuery.data)
    ),
  };
};

const queryForMetadataFn = (
  user: BaseExtendedUser,
  metadata: "admin" | "business",
  enabled: boolean
) => {
  return {
    queryKey: getUserMetadataQueryKey(user.tenant_id, user.id, metadata),
    queryFn: async () => {
      const data = await adminIdentityUsersApi.getUserMetadata({
        userID: user.id!,
        ipID: user.user_pool_id,
        metadataType: metadata,
      });
      return data.data;
    },
    enabled,
  };
};

const mapUserMetadataAndBusinessMetadataFn = (
  user: BaseExtendedUser,
  queryClient: QueryClient,
  checkPoolPermissions?: PoolPermissionsResponse
) => ({
  ...user,
  ...(checkPoolPermissions?.b2b_read_admin_metadata
    ? {
        metadata: queryClient.getQueryData<UserMetadata>(
          getUserMetadataQueryKey(user.tenant_id, user.id, "admin")
        )?.metadata,
      }
    : {}),
  ...(checkPoolPermissions?.b2b_read_business_metadata
    ? {
        business_metadata: queryClient.getQueryData<UserMetadata>(
          getUserMetadataQueryKey(user.tenant_id, user.id, "business")
        )?.metadata,
      }
    : {}),
});

export const useCreatedUsers = <T extends { id?: string }>() => {
  const [createdUsers, setCreatedUsers] = useState<T[]>([]);
  const seenUsersIds = useRef(new Set<string>());

  const addCreatedUser = useCallback((createdUser: T) => {
    setCreatedUsers(users => [createdUser, ...(users ?? [])]);
  }, []);

  const mergeCreatedUsersWithData = useCallback(
    (
      totalDataParams: { totalData?: T[]; includeCreatedUsers: boolean },
      dataParams: { data?: T[]; includeCreatedUsers: boolean }
    ) => {
      createdUsers
        .filter(user => dataParams.data?.some(u => u.id === user.id))
        .forEach(user => {
          if (user.id) {
            seenUsersIds.current.add(user.id);
          }
        });

      const highlighedUsers = createdUsers;
      const createdUsersNotSeen =
        createdUsers.filter(user => user.id && !seenUsersIds.current.has(user.id)) ?? [];

      const createdUsersForTotalData = totalDataParams.includeCreatedUsers
        ? [...createdUsersNotSeen, ...(totalDataParams.totalData ?? [])]
        : totalDataParams.totalData;

      const createdUsersForData = dataParams.includeCreatedUsers
        ? [...createdUsersNotSeen, ...(dataParams.data ?? [])]
        : dataParams.data;

      return totalDataParams.totalData && dataParams.data
        ? {
            highlighedUsers,
            totalData: createdUsersForTotalData,
            data: createdUsersForData,
          }
        : {
            highlighedUsers: undefined,
            totalData: undefined,
            data: undefined,
          };
    },
    [createdUsers, seenUsersIds]
  );

  return {
    addCreatedUser,
    mergeCreatedUsersWithData,
  };
};

export const useListUsersWithPagination = (
  identityPoolID: string
): AdvancedTableData<ExtendedUser> => {
  return useGenericListUsersWithPagination<ExtendedUser>(
    identityPoolID,
    useListUsers<ExtendedUser>
  );
};

export const useClientSideAdvancedTableData = <T extends { id?: string | undefined }>(
  serverSideAdvancedTableData: Partial<AdvancedTableData<T>>
): AdvancedTableData<T> => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState<string>();
  const [order, setOrder] = useState("desc");
  const { addCreatedUser, mergeCreatedUsersWithData } = useCreatedUsers<T>();
  const globalStoreContext = useContext(GlobalStoreContext);

  if (sort) {
    serverSideAdvancedTableData.totalData?.sort((a, b) => {
      const [first, second] = order === "asc" ? [a[sort], b[sort]] : [b[sort], a[sort]];

      if (typeof first === "number" && typeof second === "number") {
        return first - second;
      }

      return String(first).localeCompare(String(second));
    });
  }

  const pageData = serverSideAdvancedTableData.totalData?.slice(page * limit, (page + 1) * limit);
  const hasNext = (serverSideAdvancedTableData.totalData?.length ?? 0) > (page + 1) * limit;
  const hasPrevious = page > 0;

  useEffect(() => {
    setPage(0);
  }, [serverSideAdvancedTableData.totalData, limit]);

  const {
    highlighedUsers,
    totalData: finalTotalData,
    data: finalPageData,
  } = useMemo(() => {
    const {
      highlighedUsers,
      totalData: totalDataWithCreatedUsers,
      data: dataWithCreatedUsers,
    } = mergeCreatedUsersWithData(
      { totalData: serverSideAdvancedTableData.totalData, includeCreatedUsers: true },
      { data: pageData, includeCreatedUsers: !page }
    );
    const totalDataWithoutDeleted =
      globalStoreContext.deletedUsers.filterDeletedUsersFromData(totalDataWithCreatedUsers);
    const dataWithoutDeleted =
      globalStoreContext.deletedUsers.filterDeletedUsersFromData(dataWithCreatedUsers);

    return {
      highlighedUsers,
      totalData: totalDataWithoutDeleted,
      data: dataWithoutDeleted,
    };
  }, [
    mergeCreatedUsersWithData,
    globalStoreContext,
    serverSideAdvancedTableData.totalData,
    pageData,
    page,
  ]);

  return {
    isClientSide: true,
    totalData: finalTotalData,
    data: finalPageData,
    isLoading: false,
    isSuccess: true,
    params: { query: serverSideAdvancedTableData.params?.query, limit },
    onReset: serverSideAdvancedTableData.onReset ?? (() => {}),
    onSetSort: setSort,
    onSetOrder: setOrder,
    onSetLimit: setLimit,
    onSetQuery: serverSideAdvancedTableData.onSetQuery ?? (() => {}),
    onNext: () => setPage(page => page + 1),
    onBack: () => setPage(page => page - 1),
    hasNext,
    hasPrevious,
    highlighedUsers,
    addCreatedUser,
  };
};

const useGenericListUsersWithPagination = <T extends { id?: string | undefined }>(
  identityPoolID: string,
  listUsersFn: (req: UsersApiListUsersRequest | B2BUsersApiListUsersB2BRequest, options?) => any
): AdvancedTableData<T> => {
  const [sort, setSort] = useState();
  const [order, setOrder] = useState();
  const [afterUserId, setAfterUserId] = useState();
  const [beforeUserId, setBeforeUserId] = useState();
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState();
  const { addCreatedUser, mergeCreatedUsersWithData } = useCreatedUsers<T>();
  const globalStoreContext = useContext(GlobalStoreContext);

  const requestParams = { sort, order, limit, query };
  const usersQuery = listUsersFn({
    ipID: identityPoolID,
    ...requestParams,
    afterUserId,
    beforeUserId,
  });

  const totalUsersQuery = listUsersFn(
    { ipID: identityPoolID, ...requestParams, limit: 100 },
    { refetchOnMount: "always" }
  );

  const nextUsersQuery = listUsersFn({
    ipID: identityPoolID,
    ...requestParams,
    beforeUserId: undefined,
    afterUserId: usersQuery.isFetched ? usersQuery.data?.at(-1)?.id : undefined,
  });

  const previousUsersQuery = listUsersFn({
    ipID: identityPoolID,
    ...requestParams,
    beforeUserId: usersQuery.isFetched ? usersQuery.data?.at(0)?.id : undefined,
    afterUserId: undefined,
  });

  const onReset = useCallback(() => {
    setBeforeUserId(undefined);
    setAfterUserId(undefined);
  }, []);

  const onSetSort = useCallback(
    v => {
      setSort(v);
      onReset();
    },
    [onReset]
  );

  const onSetOrder = useCallback(
    v => {
      setOrder(v);
      onReset();
    },
    [onReset]
  );

  const onSetQuery = useCallback(
    v => {
      if (v !== query) {
        setQuery(v);
        onReset();
      }
    },
    [onReset, query]
  );

  const onSetLimit = useCallback(
    v => {
      if (v !== limit) {
        setLimit(v);
        onReset();
      }
    },
    [onReset, limit]
  );

  const onSetAfterItemId = useCallback(v => {
    setBeforeUserId(undefined);
    setAfterUserId(v);
  }, []);

  const onSetBeforeItemId = useCallback(v => {
    setAfterUserId(undefined);
    setBeforeUserId(v);
  }, []);

  const onNext = useCallback(() => {
    onSetAfterItemId(usersQuery.data?.at(-1)?.id);
  }, [usersQuery.data, onSetAfterItemId]);

  const onBack = useCallback(() => {
    onSetBeforeItemId(usersQuery.data?.at(0)?.id);
  }, [usersQuery.data, onSetBeforeItemId]);

  const hasNext = (nextUsersQuery.data?.length ?? 0) > 0;
  const hasPrevious = (previousUsersQuery.data?.length ?? 0) > 0;

  const { highlighedUsers, totalData, data } = useMemo(() => {
    const {
      highlighedUsers,
      totalData: totalDataWithCreatedUsers,
      data: dataWithCreatedUsers,
    } = mergeCreatedUsersWithData(
      { totalData: totalUsersQuery.data, includeCreatedUsers: !query },
      { data: usersQuery.data, includeCreatedUsers: !hasPrevious && !query }
    );
    const totalDataWithoutDeleted =
      globalStoreContext.deletedUsers.filterDeletedUsersFromData(totalDataWithCreatedUsers);
    const dataWithoutDeleted =
      globalStoreContext.deletedUsers.filterDeletedUsersFromData(dataWithCreatedUsers);

    return {
      highlighedUsers,
      totalData: totalDataWithoutDeleted,
      data: dataWithoutDeleted,
    };
  }, [
    mergeCreatedUsersWithData,
    globalStoreContext,
    totalUsersQuery.data,
    usersQuery.data,
    hasPrevious,
    query,
  ]);

  const serverSideAdvancedTableData: AdvancedTableData<T> = {
    isClientSide: false,
    totalData,
    data,
    isLoading: usersQuery.isLoading,
    isSuccess: usersQuery.isSuccess,
    params: requestParams,
    onReset,
    onSetSort,
    onSetOrder,
    onSetLimit,
    onSetQuery,
    onNext,
    onBack,
    hasNext,
    hasPrevious,
    highlighedUsers,
    addCreatedUser,
  };

  const clientSideAdvancedTableData = useClientSideAdvancedTableData(serverSideAdvancedTableData);

  return (serverSideAdvancedTableData.totalData?.length ?? 0) < 100
    ? clientSideAdvancedTableData
    : serverSideAdvancedTableData;
};

export const useListB2BUsers = <T>(req: B2BUsersApiListUsersB2BRequest, options?) => {
  return useGenericListUsers<T>(
    () => b2bUsersApi.listUsersB2B(req),
    [...listB2BUsersQueryKey(getTenantId(), req.ipID), req],
    userId => getB2BUserQueryKey(getTenantId(), userId),
    options
  );
};

export const useListUsers = <T>(req: UsersApiListUsersRequest, options?) => {
  return useGenericListUsers<T>(
    () => identityUsersApi.listUsers(req),
    [...listUsersQueryKey(getTenantId(), req.ipID), req],
    userId => getUserQueryKey(getTenantId(), userId),
    options
  );
};

const useGenericListUsers = <T>(
  fn: () => Promise<AxiosResponse<Users | B2BUsers, any>>,
  listQueryKey: string[],
  getSingleQueryKey: (userId: string) => string[],
  options
) => {
  const queryClient = useQueryClient();

  return useQuery<T[]>({
    queryKey: listQueryKey,
    queryFn: withQueryError<T[]>(async () => {
      const data = await fn();

      return (data.data.users ?? []).map(user => {
        const cachedUser = queryClient.getQueryData<UserWithData>(getSingleQueryKey(user.id));

        if (
          cachedUser &&
          (bigDecimal.compareTo(
            dateWithNanoAsString(cachedUser?.updated_at),
            dateWithNanoAsString(user.updated_at)
          ) > 0 ||
            bigDecimal.compareTo(
              dateWithNanoAsString(cachedUser?.status_updated_at),
              dateWithNanoAsString(user.status_updated_at)
            ) > 0)
        ) {
          return convertUserWithDataToExtendedUser(cachedUser);
        }

        return user;
      });
    }, "Error occurred while trying to list users"),
    ...{ keepPreviousData: true, ...options },
  });
};

const dateWithNanoAsString = (updatedAt?: string) => {
  if (!updatedAt) {
    return "0";
  }

  const dateAsValue = new Date(updatedAt.split(".")[0]).valueOf();
  const nano = updatedAt.split(".")[1].replace("Z", "").padEnd(9, "0");

  return dateAsValue + nano;
};

export const convertUserWithDataToExtendedUser = (userWithData: UserWithData): ExtendedUser => ({
  ...userWithData,
  status: convertUserWithDataStatusEnumExtendedUserStatusEnum(userWithData.status),
  identifiers: (userWithData?.identifiers || []).map(identifier => identifier.identifier),
  // @ts-ignore FIX on BE - underscore instead of space
  "verified addresses": (userWithData?.verifiable_addresses || [])
    .filter(address => !!address.verified)
    .map(address => address.address),
});

export const convertUserWithDataToBaseExtendedUser = (
  userWithData: UserWithData
): BaseExtendedUser => ({
  ...userWithData,
  status: convertUserWithDataStatusEnumBaseExtendedUserStatusEnum(userWithData.status),
  identifiers: (userWithData?.identifiers || []).map(identifier => identifier.identifier),
  // @ts-ignore FIX on BE - underscore instead of space
  "verified addresses": (userWithData?.verifiable_addresses || [])
    .filter(address => !!address.verified)
    .map(address => address.address),
});

export const convertBaseUserWithDataToBaseExtendedUser = (
  userWithData: BaseUserWithData
): BaseExtendedUser => ({
  ...userWithData,
  status: convertBaseUserWithDataStatusEnumBaseExtendedUserStatusEnum(userWithData.status),
  identifiers: (userWithData?.identifiers || []).map(identifier => identifier.identifier),
  // @ts-ignore FIX on BE - underscore instead of space
  "verified addresses": (userWithData?.verifiable_addresses || [])
    .filter(address => !!address.verified)
    .map(address => address.address),
});

const convertUserWithDataStatusEnumExtendedUserStatusEnum = (from: UserWithDataStatusEnum) => {
  switch (from) {
    case UserWithDataStatusEnum.Active:
      return ExtendedUserStatusEnum.Active;
    case UserWithDataStatusEnum.Inactive:
      return ExtendedUserStatusEnum.Inactive;
    case UserWithDataStatusEnum.Deleted:
      return ExtendedUserStatusEnum.Deleted;
    case UserWithDataStatusEnum.New:
      return ExtendedUserStatusEnum.New;
    default:
      return from;
  }
};

const convertUserWithDataStatusEnumBaseExtendedUserStatusEnum = (from: UserWithDataStatusEnum) => {
  switch (from) {
    case UserWithDataStatusEnum.Active:
      return BaseExtendedUserStatusEnum.Active;
    case UserWithDataStatusEnum.Inactive:
      return BaseExtendedUserStatusEnum.Inactive;
    case UserWithDataStatusEnum.Deleted:
      return BaseExtendedUserStatusEnum.Deleted;
    case UserWithDataStatusEnum.New:
      return BaseExtendedUserStatusEnum.New;
    default:
      return from;
  }
};

const convertBaseUserWithDataStatusEnumBaseExtendedUserStatusEnum = (
  from: BaseUserWithDataStatusEnum
) => {
  switch (from) {
    case BaseUserWithDataStatusEnum.Active:
      return BaseExtendedUserStatusEnum.Active;
    case BaseUserWithDataStatusEnum.Inactive:
      return BaseExtendedUserStatusEnum.Inactive;
    case BaseUserWithDataStatusEnum.Deleted:
      return BaseExtendedUserStatusEnum.Deleted;
    case BaseUserWithDataStatusEnum.New:
      return BaseExtendedUserStatusEnum.New;
    default:
      return from;
  }
};
