import React, { useEffect, useMemo, useRef, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import { getTenantId, previewBasePath } from "../../../../../common/api/paths";
import { useGetStyling } from "../../../../../common/api/stylingQuery";
import { getDefaultStyling, getStyling } from "../../../../../common/utils/hooks/useStyling";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import { getBasicStyle } from "../../../customBranding/Preview";
import {
  createIdpButton,
  createPoolMethodButton,
  handleAddSeparator,
  handleIdpDiscoveryEnabled,
  handlePasswordMethodFields,
  handlePoolNoMethods,
} from "./utils";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "64px 16px 24px",
    background: "white",
    backgroundImage: "radial-gradient(#F4F4F4 1.5px, transparent 0)",
    backgroundSize: "8px 8px",
    backgroundPosition: "-4px -4px",
    border: "1px solid #E9EBFA",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  previewContainer: {
    position: "relative",
    flex: 1,
    maxWidth: 530,
    height: "100%",
    width: "100%",
  },
  preview: {
    pointerEvents: "none",
    border: "solid 1px lightgray",
    borderRadius: 4,
    padding: "0px 4px",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  previewLabel: {
    background: theme.custom.sa.greys.g40,
    padding: "6px 36px",
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    position: "absolute",
    top: 0,
    boxShadow: theme.custom.shadows.surfaceResting,
    textAlign: "center",
  },
}));

const defaultUrl = `${previewBasePath}/login/preview?preview=true`;
const identityPoolUrl = `${previewBasePath}/preview?page=identity/login/index.tmpl&preview=true`;

interface Props {
  workspace: string;
  poolIdps: IDP[];
  externalIdps: IDP[];
  pool: PoolResponse | undefined;
}

export default function IdentitiesListSimplePreview({
  workspace,
  poolIdps,
  externalIdps,
  pool,
}: Props) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState(defaultUrl);
  const [progress, setProgress] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const idpsForPreview = [
    ...poolIdps
      .filter(idp => !idp.hidden)
      .map(idp => (!idp.disabled ? { method: idp.method ?? "", name: idp.name ?? "" } : null))
      .filter((v): v is { method: string; name: string } => !!v),
    ...externalIdps
      .filter(idp => !idp.hidden)
      .map(idp => (!idp.disabled ? { name: idp.name ?? "", method: idp.method ?? "" } : null))
      .filter((v): v is { method: string; name: string } => !!v),
  ].filter(v => v);

  const getAuthorizationServerQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const idpDiscoveryEnabled = !!getAuthorizationServerQuery.data?.idp_discovery?.enabled;

  const handlePoolOptions = (content: Document) => {
    const form = content.querySelector("#sign-in-form") as HTMLElement;
    const poolMethodsContainer = content.querySelector("#pool-methods-container") as HTMLElement;
    const header = content.querySelector("#log-in-header") as HTMLElement;
    const elementWithMargin = content.querySelector(
      ".pool-login-remember-idp-switch-container"
    ) as HTMLElement;
    const methods = pool?.authentication_mechanisms ?? [];

    if (pool && form) {
      if (poolMethodsContainer) {
        form.removeChild(poolMethodsContainer);
      }

      const hasPassword = methods.includes(PoolResponseAuthenticationMechanismsEnum.Password);
      const hasOtp = methods.includes(PoolResponseAuthenticationMechanismsEnum.Otp);
      const hasWebauthn = methods.includes(PoolResponseAuthenticationMechanismsEnum.Webauthn);

      const methodsContainer = document.createElement("div");
      methodsContainer.id = "pool-methods-container";

      handleAddSeparator(methodsContainer, hasPassword && methods.length > 1);
      handlePoolNoMethods(form, methods.length === 0);
      handlePasswordMethodFields(content, hasPassword);

      if (hasOtp) {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Otp)
        );
      }

      if (hasWebauthn) {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Webauthn)
        );
      }

      if (header) {
        if (hasPassword) {
          header.textContent = "Log in with password";
          if (elementWithMargin) {
            elementWithMargin.style.marginTop = "32px";
          }
        } else {
          header.textContent = "Log in with";
          if (elementWithMargin) {
            elementWithMargin.style.marginTop = "0px";
          }
        }
      }

      form.appendChild(methodsContainer);
    }
  };

  const getStylingQuery = useGetStyling(workspace);
  const defaultStyling = getDefaultStyling(theme);
  const styling = useMemo(
    () => getStyling({ styling: getStylingQuery.data, defaults: defaultStyling }),
    [defaultStyling, getStylingQuery.data]
  );
  const styles = useMemo(() => getBasicStyle(styling, {}, "workspace"), [styling]);

  const reloadIframeContent = () => {
    let cleanup = () => {};

    setProgress(true);
    if (iframeRef?.current) {
      let content =
        iframeRef.current?.contentWindow?.document || iframeRef.current?.contentDocument;
      if (content) {
        const card = content.querySelector(".card") as HTMLElement;
        const header = content.querySelector(".header-container") as HTMLElement;
        if (card && header) {
          card.style.transform = "scale(0.83)";
          card.style.marginTop = "0";
          header.style.transform = "scale(0.83)";
          header.style.width = "120%";
          header.style.marginLeft = "-10%";
        }
        let newUrl = url;
        if (idpsForPreview.length === 1 && idpsForPreview[0].method === "identity_pool") {
          newUrl = identityPoolUrl;
          handlePoolOptions(content);
        } else {
          newUrl = defaultUrl;
          handleIdpDiscoveryEnabled(content, idpDiscoveryEnabled);
        }

        if (url !== newUrl) {
          setUrl(newUrl);
          setIsVisible(false);
        }

        const idpsList = content.querySelector("#idps-list") as HTMLElement;
        if (idpsList) {
          idpsList.style.maxHeight = "unset";
          idpsList.innerHTML = "";

          idpsForPreview.forEach(({ name, method }) => {
            const button = createIdpButton(name, method);
            if (button) {
              idpsList.appendChild(button);
            }
          });
        }

        if (content.head) {
          const styleTag = content.querySelector("style.branding");
          if (!styleTag) {
            const style = content.createElement("style");
            style.id = "branding";
            style.textContent = styles;
            content.head.appendChild(style);
          } else {
            styleTag.textContent = styles;
          }
        }

        function blurActiveElement() {
          (content?.activeElement as Partial<HTMLElement>)?.blur?.();
        }

        content.addEventListener("focusin", blurActiveElement);
        cleanup = () => content.removeEventListener("focusin", blurActiveElement);
      }
    }
    setProgress(false);
    return cleanup;
  };

  useEffect(() => {
    if (loaded) {
      return reloadIframeContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, idpsForPreview, styles, isVisible]);

  const loading = !isVisible || progress || getAuthorizationServerQuery.isLoading;

  return (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.progress} />}
      <span className={classes.previewLabel}>Login page preview</span>
      <div className={classes.previewContainer}>
        <iframe
          src={url}
          width="100%"
          height="100%"
          title="preview"
          className={classes.preview}
          ref={iframeRef}
          style={isVisible ? {} : { visibility: "hidden" }}
          onLoad={() => {
            setLoaded(true);
            setIsVisible(true);
          }}
        />
      </div>
    </div>
  );
}
