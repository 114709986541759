import React, { CSSProperties, ReactNode } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia, { CardMediaProps } from "@mui/material/CardMedia";
import { makeStyles } from "tss-react/mui";

const useActionStyles = makeStyles()(theme => ({
  buttons: {
    display: "flex",
    gridGap: 10,
    marginLeft: "auto",
  },
  paper: {
    overflow: "auto",
    borderRadius: 4,
    padding: 12,
  },
  progress: {
    ...(theme.typography.caption as CSSProperties),
    color: theme.custom.greys.textDisabled,
  },
  title: {
    padding: "0 0 4px 0",
    color: theme.palette.primary.main,
  },
}));

export const StepTitle = (props: { id?: string; children: ReactNode }) => {
  const { classes } = useActionStyles();

  return (
    <CardHeader
      {...(props.id ? { id: props.id } : {})}
      title={props.children}
      className={classes.title}
      titleTypographyProps={{ style: { fontSize: 16 } }}
    />
  );
};

export const StepContent = (props: { children: ReactNode }) => {
  return <CardContent style={{ padding: "4px 0 0 0" }}>{props.children}</CardContent>;
};

export const StepMedias = (props: CardMediaProps & { children: ReactNode }) => {
  return <CardMedia image={props.image} style={{ height: 200 }} />;
};

export interface StepActionsProps {
  cardStyles?: CSSProperties;
  onNext?: () => void;
  onPrev?: () => void;
  onClose?: () => void;
  onMore?: () => void;
  nextLabel?: string;
  progressText?: string;
  moreLabel?: string;
  children?: ReactNode;
}

export const StepActions = (props: StepActionsProps) => {
  const { classes } = useActionStyles();
  return (
    <CardActions disableSpacing={true} style={{ padding: "12px 0 0 0" }}>
      <div className={classes.progress}>{props.progressText}</div>
      <div className={classes.buttons} id="back-button">
        {props.onMore && (
          <Button onClick={props.onMore} id="learn-more-button" color="primary" variant="text">
            {props.moreLabel || "Learn more"}
          </Button>
        )}
        {props.onPrev && (
          <Button onClick={props.onPrev} color="primary">
            Back
          </Button>
        )}
        {props.onNext && (
          <Button onClick={props.onNext} id="next-button" color="primary" variant="contained">
            {props.nextLabel || "Next"}
          </Button>
        )}
        {props.onClose && (
          <Button onClick={props.onClose} id="close-button" color="primary" variant="contained">
            Close
          </Button>
        )}
      </div>
    </CardActions>
  );
};

export const StepContainer = ({ cardStyles, children }: StepActionsProps) => {
  const { classes } = useActionStyles();

  return (
    <Card className={classes.paper} style={cardStyles}>
      {children}
    </Card>
  );
};
